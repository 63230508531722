import {Point} from "@/model/point";
import * as Collections from 'typescript-collections';
import {Replica} from "@/store/meet/types";

export interface PlaceState {
    tiles: Tile[];
    places: Place[];
    educations: Education[];
    maxCoordinate: number;
    idsCache: Collections.Set<number>;
}

export class Tile {
    id!: number;
    textureKey!: string;
    yfrom!: number;
    yto!: number;
    textureLength!: number;
}

export class Place {
    id!: number;
    placeType!: PlaceType;
    posX!: number;
    posY!: number;
    creatureId!: string | null;
    creatureLevel!: number | null;
    creatureNamePlural!: string | null;
    special!: boolean;
    battleLost!: boolean;
    positionType!: PositionType;
    mandatory!: boolean;
    dropShadow!: boolean;

    educations!: Education[];

    talks!: Talk[];

    accept(visitor: PlaceVisitor): void {}

    getPosition(): Point {
        return new Point(this.posX, this.posY);
    }
}

export enum PlaceType {
    RESOURCE = 'RESOURCE',
    CUSTOM = 'CUSTOM_PLACE',
    ARTIFACT = 'ARTIFACT',
    RUNE = 'RUNE',
    CONSUMABLE = 'CONSUMABLE',
    POINTER = 'POINTER',
    TURN = 'TURN',
    GAINABLE = 'GAINABLE',
    MAGIC = 'MAGIC',
    EXCHANGE = 'EXCHANGE',
    PORTAL = 'PORTAL',
    SKILL = 'SKILL',
    TALKER = 'TALKER'
}

export enum ResourceType {
    GOLD = 'GOLD',
    CRYSTAL = 'CRYSTAL',
    ELIXIR = 'ELIXIR',
    MITHRIL = 'MITHRIL',
    SULFUR = 'SULFUR',
    CAVIAR = 'CAVIAR',
    TICKETS = 'TICKETS',
    EVENT_CURRENCY = 'EVENT_CURRENCY',
}

export enum SkillType {
    ATTACK = 'ATTACK',
    DEFENCE = 'DEFENCE',
    HEALTH = 'HEALTH',
    IMMORTALITY = 'IMMORTALITY',
    POISON = 'POISON'
}

export enum PositionType {
    SMALL = 'SMALL',
    BIG = 'BIG',
    WATER_SMALL = 'WATER_SMALL',
    WATER_BIG = 'WATER_BIG',
}

export enum PlaceDisplayType {
    PANEL = 'PANEL',
    DIALOG = 'DIALOG'
}

export enum AnimType {
    NONE = 'NONE',
    TEETER = 'TEETER',
    FLYING = 'FLYING',
    FLY_VERTICAL = 'FLY_VERTICAL',
    CAT_DROWN_MALE = 'CAT_DROWN_MALE',
    CAT_DROWN_FEMALE = 'CAT_DROWN_FEMALE',
    EMPTY = 'EMPTY'
}

export class Talk {
    delayMs!: number;
    text!: string;
}

export class Education {
    id!: string;
    triggerType!: TriggerType;
    distanceFromTopOfScreen!: number;
    delayMs!: number;
    eduBlock: EduBlock | undefined;
    heroComment: HeroComment | undefined;
    scriptId: string | undefined;
    postScriptId: string | undefined;
    giveMarker: string | undefined;
    removeMarker: string | undefined;
    arrow!: boolean;
    arrowX!: number;
    arrowY!: number;
    arrowMode!: ArrowMode;
    roadHand!: boolean;
    stopMovement!: boolean;
    forbidMovement!: boolean;
    requiredMarkers!: string[];
    forbiddenMarkers!: string[];
}

export class HeroComment {
    heroId!: number;
    text!: string;
    commentDuration!: number | undefined;
}

export class EduBlock {
    atlasId!: string;
    imageId!: string;
    text!: string;
    answer!: string;
}

export enum ArrowMode {
    PERSISTENT = 'PERSISTENT',
    PERIODIC = 'PERIODIC',
    ONE_TIME = 'ONE_TIME'
}

export enum TriggerType {
    UNKNOWN = 'UNKNOWN',
    DELAY = 'DELAY',
    DISTANCE = 'DISTANCE',
    BATTLE_WON = 'BATTLE_WON',
    BATTLE_LOST = 'BATTLE_LOST',
    COMPLETE = 'COMPLETE',
    PREMISS = 'PREMISS',
    MISS = 'MISS'
}

export class ResourcePlace extends Place {
    resourceType!: ResourceType;
    count!: number;

    public accept(visitor: PlaceVisitor): void {
        visitor.visitResourcePlace(this);
    }
}

export class CustomPlace extends Place {
    placeId!: string;
    atlasId!: string;
    imageId!: string;
    animationId: string | undefined;
    placeDisplayType!: PlaceDisplayType;
    lighting!: boolean;
    lightingX!: number;
    lightingY!: number;

    animType!: AnimType;
    shadowOffset!: number;
    shadowScale!: number;

    firstReplica: Replica | undefined;
    used!: boolean;
    completed!: boolean;

    public accept(visitor: PlaceVisitor): void {
        visitor.visitCustomPlace(this);
    }
}

export class ArtifactPlace extends Place {
    artifactId!: string;
    atlasId!: string;
    imageId!: string;

    public accept(visitor: PlaceVisitor): void {
        visitor.visitArtifactPlace(this);
    }
}

export class RunePlace extends Place {
    runeId!: string;
    atlasId!: string;
    imageId!: string;

    public accept(visitor: PlaceVisitor): void {
        visitor.visitRunePlace(this);
    }
}

export class ConsumablePlace extends Place {
    consumableId!: string;
    imageId!: string;

    public accept(visitor: PlaceVisitor): void {
        visitor.visitConsumablePlace(this);
    }
}

export class PointerPlace extends Place {
    comment!: string;
    imageId!: string;

    public accept(visitor: PlaceVisitor): void {
        visitor.visitPointerPlace(this);
    }
}

export class TurnPlace extends Place {
    comment!: string;
    imageId!: string;
    lighting!: boolean;
    lightingX!: number;
    lightingY!: number;

    public accept(visitor: PlaceVisitor): void {
        visitor.visitTurnPlace(this);
    }
}

export class GainablePlace extends Place {
    placeId!: string;

    public accept(visitor: PlaceVisitor): void {
        visitor.visitGainablePlace(this);
    }
}

export class MagicPlace extends Place {
    placeId!: string;
    imageId!: string;
    used!: boolean;

    public accept(visitor: PlaceVisitor): void {
        visitor.visitMagicPlace(this);
    }
}

export class ExchangePlace extends Place {
    placeId!: string;
    atlasId!: string;
    imageId!: string;
    used!: boolean;

    public accept(visitor: PlaceVisitor): void {
        visitor.visitExchangePlace(this);
    }
}

export class PortalPlace extends Place {
    portalId!: string;
    atlasId!: string;
    imageId!: string;

    public accept(visitor: PlaceVisitor): void {
        visitor.visitPortalPlace(this);
    }
}

export class TalkerPlace extends Place {
    talkerId!: string;
    atlasId!: string;
    imageId!: string;
    phrases!: string[];

    public accept(visitor: PlaceVisitor): void {
        visitor.visitTalkerPlace(this);
    }
}

export class SkillPlace extends Place {
    skillType!: SkillType;
    count!: number;
    customAtlas?:string;
    customImage?:string;

    public accept(visitor: PlaceVisitor): void {
        visitor.visitSkillPlace(this);
    }
}


export interface PlaceVisitor {
    visitSkillPlace(place: SkillPlace): void;
    visitResourcePlace(place: ResourcePlace): void;
    visitCustomPlace(place: CustomPlace): void;
    visitArtifactPlace(place: ArtifactPlace): void;
    visitRunePlace(place: RunePlace): void;
    visitConsumablePlace(place: ConsumablePlace): void;
    visitPointerPlace(place: PointerPlace): void;
    visitTurnPlace(place: TurnPlace): void;
    visitGainablePlace(place: GainablePlace): void;
    visitMagicPlace(place: MagicPlace): void;
    visitExchangePlace(place: ExchangePlace): void;
    visitPortalPlace(place: PortalPlace): void;
    visitTalkerPlace(place: TalkerPlace): void;
}
