export class PushNotification {
    notificationType!: PushNotificationType;
}

export enum PushNotificationType {
    CHAT_MESSAGE = 'CHAT_MESSAGE',
    MAIL_MESSAGE = 'MAIL_MESSAGE',
    CHAT_MESSAGE_DELETE = 'CHAT_MESSAGE_DELETE'
}

export class ChatNotification extends PushNotification {
    senderId!: number;
}

export class MailNotification extends PushNotification {
    partnerId!: number;
    partnerName!: string;
    messageText!: string;
}
export class ChatMessageDeleteNotification extends PushNotification {
    messageId!: number;
}
