import {
    ArtifactPlace,
    ConsumablePlace,
    CustomPlace, Education, ExchangePlace, GainablePlace, MagicPlace,
    Place,
    PlaceState,
    PlaceType,
    PointerPlace, PortalPlace,
    ResourcePlace,
    RunePlace, SkillPlace, TalkerPlace,
    Tile,
    TurnPlace
} from './types';
import http from '../../axios';
import * as Collections from 'typescript-collections';
import {Balance, Player} from "@/store/player/types";
import PlayerStore from "@/store/player";

export default class PlaceStore {

    private static state: PlaceState = {
        tiles: [],
        places: [],
        educations: [],
        maxCoordinate: 1000000000000000,
        idsCache: new Collections.Set<number>()
    }

    public static getPlacesAll() {
        return PlaceStore.state.places;
    }

    public static getPlaces(coordFrom: number, coordTo: number) {
        return PlaceStore.state.places.filter(place => place.posY && coordFrom <= place.posY && place.posY < coordTo);
    }

    public static placeExists(id: number) {
        return PlaceStore.state.places.filter(place => place.id === id).length > 0;
    }

    public static getBattleLostPlace() {
        return PlaceStore.state.places.find(place => place.battleLost);
    }

    public static getTiles() {
        return PlaceStore.state.tiles;
    }

    public static getEducations() {
        return PlaceStore.state.educations;
    }

    public static getMaxCoordinate(): number {
        return PlaceStore.state.maxCoordinate;
    }

    public static CLEAR_ALL_PLACES() {
        PlaceStore.state.tiles = [];
        PlaceStore.state.places = [];
        PlaceStore.state.educations = [];
        PlaceStore.state.idsCache = new Collections.Set<number>();
    }

    public static CLEAN_UP_PLACES(maxCoordinate: number) {
        for (let place of PlaceStore.state.places) {
            if (place.posY && place.posY < maxCoordinate) {
                PlaceStore.state.idsCache.remove(place.id);
            }
        }
        PlaceStore.state.places = PlaceStore.state.places.filter(place => place.posY && place.posY >= maxCoordinate);
    }

    private static ADD_PLACES(places: Place[]) {
        for (let place of places) {
            if (!PlaceStore.state.idsCache.contains(place.id)) {
                PlaceStore.state.places.push(place);
                PlaceStore.state.idsCache.add(place.id);
            }
        }
    }

    public static REMOVE_CREATURE(placeId: number) {
        for (let place of PlaceStore.state.places) {
            if (place.id == placeId) {
                place.creatureId = null;
            }
        }
    }

    public static REMOVE_PLACE(placeId: number) {
        PlaceStore.state.places = PlaceStore.state.places.filter(place => place.id !== placeId);
    }

    public static SET_PLACE_BATTLE_LOST(placeId: number) {
        for (let place of PlaceStore.state.places) {
            if (place.id === placeId) {
                place.battleLost = true;
            }
        }
    }

    public static SET_TILES(tiles: Tile[]) {
        PlaceStore.state.tiles = tiles;
    }

    public static SET_MAX_COORD(maxCoordinate: number) {
        PlaceStore.state.maxCoordinate = maxCoordinate;
    }

    public static SET_EDUCATIONS(educations: Education[]) {
        PlaceStore.state.educations = educations;
    }

    private static SET_PLACE_USED(placeId: number) {
        for (let place of PlaceStore.state.places) {
            if (place.id === placeId) {
                if (place instanceof MagicPlace) {
                    let mp: MagicPlace = <MagicPlace>place;
                    mp.used = true;
                }
            }
        }
    }

    private static handlePlaces(places: any): void {
        let res: Place[] = [];

        for (let place of places) {
            switch (place.placeType) {
                case PlaceType.RESOURCE:
                    let rp: ResourcePlace = Object.assign(new ResourcePlace(), place);
                    res.push(rp);
                    break;
                case PlaceType.CUSTOM:
                    let cp: CustomPlace = Object.assign(new CustomPlace(), place);
                    res.push(cp);
                    break;
                case PlaceType.ARTIFACT:
                    let ap: ArtifactPlace = Object.assign(new ArtifactPlace(), place);
                    res.push(ap);
                    break;
                case PlaceType.RUNE:
                    let r: RunePlace = Object.assign(new RunePlace(), place);
                    res.push(r);
                    break;
                case PlaceType.CONSUMABLE:
                    let cons: ConsumablePlace = Object.assign(new ConsumablePlace(), place);
                    res.push(cons);
                    break;
                case PlaceType.POINTER:
                    let pp: PointerPlace = Object.assign(new PointerPlace(), place);
                    res.push(pp);
                    break;
                case PlaceType.TURN:
                    let tp: TurnPlace = Object.assign(new TurnPlace(), place);
                    res.push(tp);
                    break;
                case PlaceType.GAINABLE:
                    let gp: GainablePlace = Object.assign(new GainablePlace(), place);
                    res.push(gp);
                    break;
                case PlaceType.MAGIC:
                    let mp: MagicPlace = Object.assign(new MagicPlace(), place);
                    res.push(mp);
                    break;
                case PlaceType.EXCHANGE:
                    let ep: ExchangePlace = Object.assign(new ExchangePlace(), place);
                    res.push(ep);
                    break;
                case PlaceType.PORTAL:
                    let ppp: PortalPlace = Object.assign(new PortalPlace(), place);
                    res.push(ppp);
                    break;
                case PlaceType.SKILL:
                    let sp: SkillPlace = Object.assign(new SkillPlace(), place);
                    res.push(sp);
                    break;
                case PlaceType.TALKER:
                    let t: TalkerPlace = Object.assign(new TalkerPlace(), place);
                    res.push(t);
                    break;
            }
        }

        PlaceStore.ADD_PLACES(res);
    }

    public static fetchPlaces(playerCoordinate: number, coordFrom: number, coordTo: number) {
        PlaceStore.CLEAN_UP_PLACES(playerCoordinate - 1000);
        return http.get('/api/place/v3/ensure', {params: {coordFrom: coordFrom, coordTo: coordTo}})
            .then(r => r.data)
            .then(road => {
                PlaceStore.handlePlaces(road.places);

                let tiles = [];
                for (let tile of road.tiles) {
                    let t: Tile = Object.assign(new Tile(), tile);
                    tiles.push(t);
                }
                PlaceStore.SET_TILES(tiles);
                PlaceStore.SET_EDUCATIONS(road.educations);
                PlaceStore.SET_MAX_COORD(road.maxCoordinate);
            });
    }

    public static fetchSpecialPlaces() {
        return http.get('/api/place/special/get');
    }

    public static takeResource(placeId: number): Promise<void> {
        return http.get('/api/resourcePlace/' + placeId + '/take')
            .then(r => r.data)
            .then((balanceObj) => {
                let balance: Balance = Object.assign(new Balance(), balanceObj);
                PlayerStore.SET_BALANCE(balance, false);
                PlaceStore.REMOVE_PLACE(placeId);
            });
    }

    public static takeSkill(place: SkillPlace): Promise<void> {
        PlaceStore.REMOVE_PLACE(place.id);
        return http.get('/api/skill/place/' + place.id + '/take');
    }

    public static takeGainable(placeId: number): Promise<void> {
        return http.post('/api/place/gainable/' + placeId + '/gain')
            .then(r => r.data)
            .then((playerObj) => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
            });
    }

    public static turn(placeId: number): Promise<void> {
        return http.post('/api/turn/' + placeId + '/use')
            .then(r => r.data)
            .then((playerObj) => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER_SILENT(player);
                PlaceStore.CLEAR_ALL_PLACES();
            });
    }

    public static setUsed(placeId: number) {
        PlaceStore.SET_PLACE_USED(placeId);
    }

}
