export class HSessionUtils {

    public static getHSession(): string | undefined {
        if (localStorage) {
            let hSession = localStorage.getItem('h_session');
            if (!hSession) {
                hSession = (Math.random() + 1).toString(36);
                localStorage.setItem('h_session', hSession)
            }
            return hSession;
        }
        return undefined;
    }
}