import { CancellablePromise } from '@/utils/request-utils'
import {ClanShortInfo} from "@/store/clan/types";
import {ArenaLeague} from "@/store/arena/types";

export enum RatingsType {
  PO_SILE = 'params',
  PO_UROVNJU = 'level',
  PO_MESTU_NA_ARENE = 'arenaPosition',
  CLAN_BY_LEVEL = 'clanLevel',
  PO_EVENTU = 'eventPosition',
}

export type RatingsState = {
  ratingsMeta: RatingsMeta | null,
  ratings: { list: Array<RatingsPosition|ClanShortInfo>, type: RatingsType, filter: string },
  myRatingsPosition: RatingsPosition|ClanShortInfo | null,
  ratingsListLength: number
}

export interface RatingsPosition {
  id: number,
  name: string,
  online: boolean,
  positionNumber: number,
  param: number,
  optionalLeague: ArenaLeague,
}

export interface RatingsMeta {
  playersTotal: number,
  playersOnline: number,
}

export type RatingsData = RatingsMeta & {
  myPosition: RatingsPosition|ClanShortInfo,
  positions: Array<RatingsPosition|ClanShortInfo>,
}

export type RatingsRequestParams = {
  filter: string,
  type: string
}

export type RatingsRequestToken = {
  promise: CancellablePromise<void>,
  params: RatingsRequestParams,
  attemptsLeft: number,
}