import {Player} from "@/store/player/types";

export class DomainUtils {

    public static canChangeDomain(player: Player) {
        let h = window.location.hostname;
        return h === 'foto.zazer.mobi'
            || h ==='ok.zazer.mobi'
            || h ==='vk.zazer.mobi'
            || h ==='ya.zazer.mobi'
            || h === 'mystore.zazer.mobi'
            || h === 'beeline.zazer.mobi'
            || h === 'exe.zazer.mobi'
            || h === 'zazer.mobi';
    }

    public static getDirectDomain(): string {
        return DomainUtils.toDomain('srv1');
    }

    private static toDomain(prefix: string): string {
        let h = window.location.hostname;
        console.log('h = ' + h);
        if (h.includes('dev.lgtest.fun')) {
            return 'https://' + prefix + '.dev.lgtest.fun';
        } else if (h.includes('ya.zazer.mobi')) {
            return 'https://' + prefix + '.ya.zazer.mobi';
        } else if (h.includes('foto.zazer.mobi')) {
            return 'https://' + prefix + '.foto.zazer.mobi';
        } else if (h.includes('ok.zazer.mobi')) {
            return 'https://' + prefix + '.ok.zazer.mobi';
        } else if (h.includes('vk.zazer.mobi')) {
            return 'https://' + prefix + '.vk.zazer.mobi';
        } else if (h.includes('beeline.zazer.mobi')) {
            return 'https://' + prefix + '.beeline.zazer.mobi';
        } else if (h.includes('exe.zazer.mobi')) {
            return 'https://' + prefix + '.exe.zazer.mobi';
        } else if (h.includes('mystore.zazer.mobi')) {
            return 'https://' + prefix + '.mystore.zazer.mobi';
        } else if (h === 'zazer.mobi') {
            return 'https://' + prefix + '.zazer.mobi';
        }
        return h;
    }
}