import Scene = Phaser.Scene;
import Sprite = Phaser.GameObjects.Sprite;
import Image = Phaser.GameObjects.Image;
import {Keys} from "@/constants/key";
import {Urls} from "@/constants/urls";
import Group = Phaser.GameObjects.Group;
import TileSprite = Phaser.GameObjects.TileSprite;
import AnimationUtils from "@/utils/animation-utils";
import CU from "./c-u";
import Container = Phaser.GameObjects.Container;
import Text = Phaser.GameObjects.Text;
import Tween = Phaser.Tweens.Tween;

export default class SpriteUtils {

    public static RED_BORDER: Set<string> = new Set<string>([
        'tm1_swl_ns_evil'
    ]);

    public static BLUE_BORDER: Set<string> = new Set<string>([
        'swl_ns'
    ]);

    public static readonly STUB_IMAGE: string = Keys.CLOUD;

    private static sizesToSet: Map<Image | Sprite | TileSprite, Sizes> = new Map<Image | Sprite, Sizes>();
    private static hasSizesToSet: boolean = false;

    public static scale<T extends (Container | Sprite | Image | Text)>(c: T, x?: number, y?: number): T {
        if (!y) {
            y = x;
        }
        return <T>c.setScale(x ? x * CU.S : x, y ? y * CU.S : y);
    }

    public static scaleOriginal<T extends (Container | Sprite | Image | Text)>(c: T, x?: number, y?: number): T {
        return <T>c.setScale(x, y);
    }

    public static addAndPlayAnim(scene: Scene, x: number, y: number, animId: string, arr: Sprite[]): Sprite {
        if (scene.textures.exists(animId)) {

            AnimationUtils.createAnimation(scene, animId);
            let animSprite = scene.add.sprite(x * CU.S, y * CU.S, animId);
            SpriteUtils.scale(animSprite, 1, 1);
            animSprite.play(animId);

            arr.push(animSprite);
            return animSprite;

        } else {
            let sprite: Sprite = scene.add.sprite(x * CU.S, y * CU.S, SpriteUtils.STUB_IMAGE);
            if (Urls.IMAGES.has(animId)) {
                scene.load.image(animId, Urls.IMAGES.get(animId) + '.png');

                scene.load.addListener('filecomplete', (key: any, type: any, date: any) => {
                    if (animId === key && sprite.active) {
                        AnimationUtils.createAnimation(scene, animId);
                        SpriteUtils.doSetTexture(sprite, animId);
                        SpriteUtils.scale(sprite, 1, 1);
                        sprite.play(animId);
                        arr.push(sprite);
                    }
                });

                scene.load.start();
            } else {
                console.log('Unknown url for texture: ' + animId);
            }
            return sprite;
        }
    }

    public static addSpriteN(scene: Scene, x: number, y: number,
                            texture: string, frame?: string | number | undefined,
                            scaleX?: number, scaleY?: number): Sprite {
        let res: Sprite = this.doAddSpriteN(scene, x, y, texture, frame);

        if (scaleX || scaleY) {
            SpriteUtils.scale(res, scaleX, scaleY);
        } else {
            SpriteUtils.scale(res, 1, 1);
        }

        return res;
    }

    private static doAddSpriteN(scene: Scene, x: number, y: number, texture: string, frame?: string | number | undefined): Sprite {
        if (scene.textures.exists(texture)) {

            return scene.add.sprite(x, y, texture, frame);

        } else {
            let sprite: Sprite | Image = scene.add.sprite(x, y, SpriteUtils.STUB_IMAGE);
            return <Sprite> SpriteUtils.process(scene, sprite, texture, frame);
        }
    }

    public static addSprite(scene: Scene, x: number, y: number,
                            texture: string, frame?: string | number | undefined,
                            scaleX?: number, scaleY?: number): Sprite {
        let res: Sprite = this.doAddSprite(scene, x, y, texture, frame);

        if (scaleX || scaleY) {
            SpriteUtils.scale(res, scaleX, scaleY);
        } else {
            SpriteUtils.scale(res, 1, 1);
        }

        return res;
    }

    private static doAddSprite(scene: Scene, x: number, y: number, texture: string, frame?: string | number | undefined): Sprite {
        if (scene.textures.exists(texture)) {

            return scene.add.sprite(x * CU.S, y * CU.S, texture, frame);

        } else {
            let sprite: Sprite | Image = scene.add.sprite(x * CU.S, y * CU.S, SpriteUtils.STUB_IMAGE);
            return <Sprite> SpriteUtils.process(scene, sprite, texture, frame);
        }
    }

    public static makeImage(scene: Scene, options: ImageOptions): Image {
        const image = SpriteUtils.addImage(
            scene,
            options.x,
            options.y,
            options.texture,
            options.frame,
            options.scaleX,
            options.scaleY
        );

        options.depth != null && image.setDepth(options.depth);
        options.origin != null && image.setOrigin(options.origin);
        options.alpha != null && image.setAlpha(options.alpha);

        return image;
    };

    public static addImage(scene: Scene, x: number, y: number, texture: string, frame?: string | number | undefined,
                           scaleX?: number, scaleY?: number): Image {
        let res: Image = this.doAddImage(scene, x, y, texture, frame);

        if (scaleX || scaleY) {
            SpriteUtils.scale(res, scaleX, scaleY);
        } else {
            SpriteUtils.scale(res, 1, 1);
        }

        return res;
    }

    public static doAddImage(scene: Scene, x: number, y: number, texture: string, frame?: string | number | undefined): Image {
        if (!texture || texture.length === 0) {
            throw new Error('texture null');
        }
        if (scene.textures.exists(texture)) {

            return scene.add.image(x * CU.S, y * CU.S, texture, frame);

        } else {
            let sprite: Image = scene.add.image(x * CU.S, y * CU.S, SpriteUtils.STUB_IMAGE);
            return <Image> SpriteUtils.process(scene, sprite, texture, frame);
        }
    }

    public static addTileSprite(scene: Scene, x: number, y: number, w: number, h: number,
                                texture: string, frame?: string | number | undefined): TileSprite {
        if (scene.textures.exists(texture)) {

            return scene.add.tileSprite(x, y, w / CU.S, h / CU.S, texture, frame)
                .setScale(CU.S, CU.S);

        } else {
            let sprite: TileSprite = scene.add.tileSprite(x, y, w / CU.S, h / CU.S, SpriteUtils.STUB_IMAGE)
                .setScale(CU.S, CU.S);
            return <TileSprite> SpriteUtils.process(scene, sprite, texture, frame);
        }
    }

    public static groupCreate(scene: Scene, group: Group, x: number, y: number,
                              texture: string, frame: string | undefined = undefined, scaleX?: number, scaleY?: number) {
        let res = this.doGroupCreate(scene, group, x, y, texture, frame);

        if (scaleX || scaleY) {
            SpriteUtils.scale(res, scaleX, scaleY);
        } else {
            SpriteUtils.scale(res, 1, 1);
        }

        return res;
    }

    public static doGroupCreate(scene: Scene, group: Group, x: number, y: number, texture: string, frame: string | undefined = undefined) {
        if (scene.textures.exists(texture)) {

            return group.create(x * CU.S, y * CU.S, texture, frame);

        } else {
            let sprite = group.create(x * CU.S, y * CU.S, this.STUB_IMAGE);
            return SpriteUtils.process(scene, sprite, texture, frame);
        }
    }

    public static setSprite(scene: Scene, sprite: Sprite | Image, texture: string, frame: string | undefined = undefined) {
        if (scene.textures.exists(texture)) {

            return sprite.setTexture(texture, frame);

        } else {
            return SpriteUtils.process(scene, sprite, texture, frame);
        }
    }

    public static setDispSize(sprite: Sprite | Image, width: number, height: number) {
        if (sprite.texture.key === SpriteUtils.STUB_IMAGE) {
            SpriteUtils.sizesToSet.set(sprite, new Sizes(width, height));
            SpriteUtils.hasSizesToSet = true;
        } else {
            sprite.setDisplaySize(width, height);
        }
    }

    private static doSetTexture(sprite: Sprite | Image | TileSprite, texture: string, frame: string | number | undefined = undefined) {
        sprite.setTexture(texture, frame);
        if (SpriteUtils.hasSizesToSet) {
            let ss = SpriteUtils.sizesToSet.get(sprite);
            if (ss) {
                sprite.setDisplaySize(ss.width, ss.height);
                SpriteUtils.sizesToSet.delete(sprite);
                SpriteUtils.hasSizesToSet = SpriteUtils.sizesToSet.size > 0;
            }
        }
    }

    private static haveDisabledAtlases = window.location.href.indexOf("disable_") > -1;

    private static process(scene: Scene, sprite: Sprite | Image | TileSprite, texture: string, frame?: string | number | undefined): Sprite | Image | TileSprite {
        try {
            if (frame) {
                if (Urls.ATLASES.has(texture)) {
                    let path = Urls.ATLASES.get(texture);
                    if (typeof frame === 'number') {
                        scene.load.spritesheet(texture, path + (Urls.ATLASES_WEBP.has(texture) ? '.webp' : '.png'), Urls.FRAME_CONFIGS.get(texture));
                    } else {
                        if (!this.haveDisabledAtlases || window.location.href.indexOf("disable_" + texture) == -1 ) {
                            scene.load.atlas(texture, path + (Urls.ATLASES_WEBP.has(texture) ? '.webp' : '.png'), path + '.json');
                        }
                    }

                    scene.load.addListener('filecomplete', (key: any, type: any, date: any) => {
                        if (key === texture && sprite.active) {
                            SpriteUtils.doSetTexture(sprite, texture, frame);
                        }
                    });
                    scene.load.start();

                } else {
                    console.log('Unknown url for atlas: ' + texture);
                }
            } else {
                if (Urls.IMAGES.has(texture)) {
                    scene.load.image(texture, Urls.IMAGES.get(texture));

                    scene.load.addListener('filecomplete', (key: any, type: any, date: any) => {
                        if (texture === key && sprite.active) {
                            SpriteUtils.doSetTexture(sprite, texture);
                        }
                    });

                    scene.load.start();
                } else {
                    console.log('Unknown url for texture: ' + texture);
                }
            }
        } catch (e: any) {
            // do nothing
        }

        return sprite;
    }

//     public static tweenChildren(parent:Group, times?:number[], presets?:NamedPreset[], loop?:boolean,  easings?:Function[]){
//         if(times && presets){
//             parent.presetTimes = times;
//             parent.presets = presets;
//             parent.presetLooped = loop;
//             parent.easings = easings;
//         }
//         SpriteUtils.doTweenChildren(parent);
//     }
//
//     private static doTweenChildren(parent:BasePanel){
//         let presets = parent.presets;
//         let position = parent.presetIndex;
//         if(!parent.presetLooped && position == presets.length-1){
//             return;
//         }
//         position = position % presets.length;
//         let from = presets[position].p;
//         let to = presets[(position + 1) % presets.length].p;
//         let easing = parent.easings? parent.easings[position] : Phaser.Easing.Linear.None;
//         let tweens = SpriteUtils.tweenChildrenFromTo(parent, parent.presetTimes[position], from, to, easing);
//         tweens[0].onComplete.add(function() {
//             parent.presetIndex+=1;
//             SpriteUtils.doTweenChildren(parent);
//         })
//     }
//
//     private static tweenChildrenFromTo(parent:Group, time:number, presetFrom:Preset[], presetTo:Preset[], easing?:Function):Tween[] {
//         let res:Tween[] = [];
//         for (let c in parent.children){
//             if (c instanceof Sprite && c.name){
//                 let from = presetFrom.filter(p => p.spriteId == c.name).shift();
//                 let to = presetTo.filter(p => p.spriteId == c.name).shift();
//                 if(from && to){
//                     res = res.concat(SpriteUtils.tweenFromTo(c, time, from, to, easing));
//                 }
//             }
//         }
//         res.forEach(t => t.timeScale = SpriteUtils.TWEEN_TIME_SCALE);
//         return res;
//     }
//
//     public static TWEEN_TIME_SCALE = 1;
//
//     public static updateTweenData(game:Phaser.Game, timeScale:number) {
//         this.TWEEN_TIME_SCALE = timeScale;
//         let allTweens = game.tweens.getAll();
//         for (var i = 0; i < allTweens.length; i++) {
//             var tween = allTweens[i];
//             tween.timeScale = timeScale;
//         }
//     };
//
//     public static tweenFromTo(sprite:Sprite, time:number, presetFrom:Preset, presetTo:Preset, easing?:Function):Tween[] {
//         var game = sprite.game;
//
//         let SC = G.SC;
//
//         // Создайте новый tween для спрайта
//         var forwardTween = game.add.tween(sprite);
//         sprite.x = presetFrom.x * SC;
//         sprite.y = presetFrom.y * SC;
//         sprite.setOrigin(presetFrom.anchorX, presetFrom.anchorY);
//         sprite.setScale( presetFrom.scaleX * SC, presetFrom.scaleY * SC);
//         sprite.rotation = presetFrom.rotation;
//
//         // Создайте объект с конечными значениями, которые вы хотите достичь
//         var toValues = {
//             x: presetTo.x * SC,
//             y: presetTo.y * SC,
//             rotation: presetTo.rotation
//         };
//
//         let finalEasing = easing || Phaser.Easing.Linear.None;
//         forwardTween.to(toValues, time, finalEasing);
//
//         // Создайте отдельные tween-ы для масштабирования по x и по y
//         var scaleXTweenForward = game.add.tween(sprite.scale);
//         scaleXTweenForward.to({ x: presetTo.scaleX * SC }, time, finalEasing);
//
//         var scaleYTweenForward = game.add.tween(sprite.scale);
//         scaleYTweenForward.to({ y: presetTo.scaleY * SC}, time, finalEasing);
//
//
//         var anchorXTweenForward = game.add.tween(sprite);
//         anchorXTweenForward.to({ originX: presetTo.anchorX }, time, finalEasing);
//
//         var anchorYTweenForward = game.add.tween(sprite);
//         anchorYTweenForward.to({ originY: presetTo.anchorY }, time, finalEasing);
//
//         forwardTween.start();
//         scaleXTweenForward.start();
//         scaleYTweenForward.start();
//         anchorXTweenForward.start();
//         anchorYTweenForward.start();
//
//         return [forwardTween, scaleXTweenForward, scaleYTweenForward, anchorXTweenForward, anchorYTweenForward];
//     }
}
//
// class Preset {
//     public spriteId: string;
//     public x: number;
//     public y: number;
//     public scaleX: number;
//     public scaleY: number;
//     public anchorX: number;
//     public anchorY: number;
//     public rotation: number;
//     public fontSize?: number;
// }
//
// class NamedPreset {
//     public name: string;
//     public p:Preset[];
//
// }

class Sizes {
    public width!: number;
    public height!: number;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }
}

export type ImageOptions = {
    x: number;
    y: number;
    texture: string;
    frame?: string | number;
    scaleX?: number;
    scaleY?: number;
    origin?: number;
    depth?: number;
    alpha?: number;
}



