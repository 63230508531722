import Scene = Phaser.Scene;
import {Point} from "@/model/point";
import {Keys} from "@/constants/key";
import {Player} from "@/store/player/types";
import PlayerUtils from "@/utils/player-utils";
import {Gender} from "@/model/gender";

export default class AnimationUtils {

    public static getKinghtIdle(player: Player): string {
        let gender = PlayerUtils.getGender(player);
        switch (gender) {
            case Gender.male:
                return Keys.ANIM_KNIGHT_IDLE_M;
            case Gender.female:
                return Keys.ANIM_KNIGHT_IDLE_W;
        }
        return Keys.ANIM_KNIGHT_IDLE_M;
    }

    public static getKinghtWalk(player: Player): string {
        let gender = PlayerUtils.getGender(player);
        switch (gender) {
            case Gender.male:
                return Keys.ANIM_KNIGHT_WALK_M;
            case Gender.female:
                return Keys.ANIM_KNIGHT_WALK_W;
        }
        return Keys.ANIM_KNIGHT_WALK_M;
    }


    public static getFrameSize(key: string): Point {
        if (key === 'dark_golem' || key === 'ice_golem' || key === 'rock_golem') {
            return new Point(115, 125);
        } else if (key === 'seth-ent') {
            return new Point(116, 144);
        } else if (key === '6-frogman-yellow' || key === '6-frogman-blue' || key === '6-frogman-pink'
            || key === '9-goblin-arc' || key === '9-goblin-sham' || key === '9-goblin-warr') {
            return new Point(58, 72);
        } else if (key === Keys.LOADING_ANIM) {
            return new Point(80, 80);
        } else if (key === Keys.ANIM_BIRD_FLIGHT) {
            return new Point(80, 80);
        } else if (key === Keys.ANIM_BLUE_BIRD_FLIGHT) {
            return new Point(80, 80);
        }
        throw new Error();
    }

    public static createAnimation(scene: Scene, key: string): Phaser.Animations.Animation | boolean | undefined {
        if (key === 'dark_golem') {
            return scene.anims.create({
                key: key,
                frames: scene.anims.generateFrameNumbers(key, {frames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]}),
                frameRate: 12,
                repeat: -1
            });
        } else if (key === 'ice_golem') {
            return scene.anims.create({
                key: key,
                frames: scene.anims.generateFrameNumbers(key, {frames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                        13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]}),
                frameRate: 18,
                repeat: -1
            });
        } else if (key === 'rock_golem') {
            return scene.anims.create({
                key: key,
                frames: scene.anims.generateFrameNumbers(key, {frames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                        13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]}),
                frameRate: 18,
                repeat: -1
            });
        } else if (key === 'seth-ent') {
            return scene.anims.create({
                key: key,
                frames: scene.anims.generateFrameNumbers(key, {frames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                        13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]}),
                frameRate: 18,
                repeat: -1
            });
        } else if (key === Keys.LOADING_ANIM) {
            return scene.anims.create({
                key: key,
                frames: scene.anims.generateFrameNumbers(key, {frames: [0, 1, 2, 3, 4, 5, 6, 7]}),
                frameRate: 12,
                repeat: -1
            });
        } else if (key === Keys.ANIM_BIRD_FLIGHT) {
            return scene.anims.create({
                key: key,
                frames: scene.anims.generateFrameNumbers(key, {frames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                        13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]}),
                frameRate: 18,
                repeat: -1
            });
        } else if (key === Keys.ANIM_BLUE_BIRD_FLIGHT) {
            return scene.anims.create({
                key: key,
                frames: scene.anims.generateFrameNumbers(key, {frames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                        13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]}),
                frameRate: 18,
                repeat: -1
            });
        }
        return undefined;
    }
}
