import {Quality} from "@/store/player/types";
import PlayerStore from "@/store/player";

export default class QualityUtils {

    public static getReccommendedWidth() {
        let quality = QualityUtils.getQuality();

        switch (quality) {
            case Quality.LOWEST:
                return 450;
            case Quality.LOW:
                return 500;
            case Quality.AUTO:
            case Quality.STANDARD:
                return 600;
            case Quality.HIGH:
                return 768;
        }
    }

    public static getQuality(): Quality {

        let quality = PlayerStore.getQuality();

        if (quality && quality !== Quality.AUTO) {
            return quality;
        }

        try {
            // @ts-ignore
            let availableMemory = performance.memory.jsHeapSizeLimit / 1000000; // will give you the JS heap size

            // return Quality.HIGH;

            if (availableMemory > 1100) { // 1130 = 6GB RAM
                return Quality.HIGH;
            } else if (availableMemory > 600) { // 793 = 3GB RAM
                return Quality.STANDARD;
            } else if (availableMemory > 450) {  // 491 = 2GB RAM
                return Quality.LOW;
            } else {
                return Quality.LOWEST;
            }

        } catch (e) {
            // do nothing
        }

        return Quality.HIGH;
    }

}
