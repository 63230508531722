export type CancellablePromise<T> = {
  promise: Promise<T>;
  cancel: () => void;
}

export const cancellable = (promise: Promise<any>) => {
  let hasBeenCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) =>
      hasBeenCanceled ? reject({isCanceled: true}) : resolve(val)
    );
    promise.catch((error) =>
      hasBeenCanceled ? reject({isCanceled: true}) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasBeenCanceled = true;
    },
  };
}
