import Scene = Phaser.Scene;
import {injectable} from "inversify";
import {SceneName} from "@/model/scene-name";
import ArtifactUtils from "@/utils/artifact-utils";
import SceneUtils from "@/utils/scene-utils";
import {PlaceService} from "@/services/place-service";
import PlayerStore from "@/store/player";
import Graphics = Phaser.GameObjects.Graphics;
import CU from "../utils/c-u";
import Group = Phaser.GameObjects.Group;
import {LoadUtils} from "@/utils/load-utils";
import {GameState} from "@/constants/game-state";
import PlaceStore from "@/store/place";

@injectable()
export class SceneService {

    public static readonly ENTER_NEW_SCENE_DELAY: number = 350;
    private readonly BACKGROUND_DEPTH: number = 10000;

    private lock: boolean = false;

    private static sceneService: SceneService;

    constructor() {
        SceneService.sceneService = this;
    }

    public startScene(currentScene: Scene, newSceneName: SceneName, width: number, height: number) {
        this.startSceneWithParams(currentScene, {}, newSceneName, width, height);
    }

    public startSceneWithParams(currentScene: Scene, params: any, newSceneName: SceneName, width: number, height: number) {
        this.fadeInBlackout(currentScene, width, height, () => {
            this.doStartSceneWithParams(currentScene, params, newSceneName, width, height);
        }, 0);
    }

    public startSceneWithParamsAndClouds(currentScene: Scene, canvas:HTMLCanvasElement,  params: any, newSceneName: SceneName, width: number, height: number, key:string) {
        this.fadeInClouds(currentScene, canvas, width, height, () => {
            this.doStartSceneWithParams(currentScene, params, newSceneName, width, height);
        }, 0, key);
    }

    public doStartSceneWithParams(currentScene: Scene, params: any, newSceneName: SceneName, width: number, height: number) {
        let self = this;
        let player = PlayerStore.getPlayer();
        if (newSceneName === SceneName.ROAD_SCENE || newSceneName === SceneName.LOAD_SCENE) {

            if (PlayerStore.getPlayer().stair > PlayerStore.getArtifactImageMaxStair()) {
                let stairFrom = PlayerStore.getArtifactImageMaxStair() + 1;
                let stairTo = stairFrom + ArtifactUtils.ARTIFACTS_RANGE_TO_LOAD;
                PlayerStore.fetchPlayerInfo(stairFrom, stairTo).then(() => {
                    if (player.requestSpecialPlaces) {
                        PlayerStore.SET_SPECIAL_PLACES_REQUESTED();
                        PlaceService.reset();
                        self.startSceneInstant(currentScene, newSceneName, params);
                    } else {
                        self.startSceneInstant(currentScene, newSceneName, params);
                    }
                });
            } else if (player.requestSpecialPlaces) {
                PlayerStore.SET_SPECIAL_PLACES_REQUESTED();
                PlaceService.reset();
                self.startSceneInstant(currentScene, newSceneName, params);
            } else {
                self.startSceneInstant(currentScene, newSceneName, params);
            }
        } else {
            self.startSceneInstant(currentScene, newSceneName, params);
        }
    }

    public startSceneInstant(currentScene: Scene, sceneName: SceneName, params?: any) {
        try {
            if (sceneName === SceneName.BATTLE_SCENE) {
                currentScene.scene.start(SceneService.getSceneKey(SceneName.BATTLE_LOAD_SCENE), params);
            } else if (sceneName === SceneName.CLAN_SCENE) {
                currentScene.scene.start(SceneService.getSceneKey(SceneName.CLAN_LOAD_SCENE), params);
            } else if (sceneName === SceneName.CREATURE_SCENE) {
                currentScene.scene.start(SceneService.getSceneKey(SceneName.CREATURE_LOAD_SCENE), params);
                console.log("creature load scene!")
            } else {
                currentScene.scene.start(SceneService.getSceneKey(sceneName), params);
            }
        } catch (e: any) {
            console.log(e);
            window.location.reload();
        }
    }

    public fadeInBlackout(currentScene: Scene, width: number, height: number, completeCallback: () => unknown, delay: number,
                          dur: number = SceneService.ENTER_NEW_SCENE_DELAY): Graphics | undefined {
        if (!this.lock) {
            const self = this;
            this.lock = true;
            return this.setBackground(currentScene, width, height, 0, 1, (background) => {
                self.lock = false;
                completeCallback();
            }, delay, dur);
        }
    }

    public fadeInClouds(currentScene: Scene, canvas:HTMLCanvasElement, width: number, height: number, completeCallback: () => unknown, delay: number,
                          key:string): {group: Group, graphics: Graphics} | Graphics | undefined {
        if (!this.lock) {
            const self = this;
            this.lock = true;
            if(GameState.CLOUDS_SHOWN_FOR_SCREEN.get(key)){
                return this.setBackground(currentScene, width, height, 0, 1, (background) => {
                    self.lock = false;
                    completeCallback();
                }, delay, SceneService.ENTER_NEW_SCENE_DELAY);
            } else {
                GameState.CLOUDS_SHOWN_FOR_SCREEN.set(key, true);
                
                return LoadUtils.drawLoadingScreen2(currentScene, 0, 1, () => {
                    self.lock = false;
                    completeCallback();
                }, delay, SceneService.ENTER_NEW_SCENE_DELAY);

                // return LoadUtils.drawClouds2(currentScene, canvas, 0, 1, () => {
                //     self.lock = false;
                //     completeCallback();
                // }, delay, SceneService.ENTER_NEW_SCENE_DELAY);
            }
        }
    }

    public cancelBackground(back: Graphics) {
        back.destroy();
        this.lock = false;
    }

    public cancelClouds({group, graphics}: {group: Group, graphics: Graphics}) {
        group.destroy();
        graphics.destroy();
        this.lock = false;
    }

    public fadeBlackout(scene: Scene, width: number, height: number) {
        this.setBackground(scene, width, height, 1,0, (background: Graphics) => {
            background.destroy();
        }, 0, SceneService.ENTER_NEW_SCENE_DELAY);
    }

    private setBackground(currentScene: Scene, width: number, height: number, fromAlpha: number, toAlpha: number,
                          completeCallback: (background: Graphics) => unknown, delay: number, dur: number): Graphics {

        let background = currentScene!.add.graphics()
            .setDepth(this.BACKGROUND_DEPTH)
            .setAlpha(fromAlpha);

        let color = Phaser.Display.Color.HexStringToColor('#000000').color;

        background.fillRect(0, 0, width, height);
        background.fillStyle(color);

        currentScene.tweens.add({
            targets: background,
            alpha: toAlpha,
            delay: delay,
            duration: dur,

            onComplete() {
                completeCallback(background);
            }
        });
        return background;
    }

    public static getSceneKey(sceneName: SceneName) : string {
        switch (sceneName) {
            case SceneName.ROAD_SCENE:
                return 'RoadScene';
            case SceneName.BATTLE_SCENE:
                return 'BattleScene';
            case SceneName.CHAR_SELECTION_SCENE:
                return 'CharSelectionScene';
            case SceneName.CREATURE_SCENE:
                return 'CreatureScene';
            case SceneName.CREATURE_LOAD_SCENE:
                return 'CreatureLoadScene';
            case SceneName.BOOT_SCENE:
                return 'BootScene';
            case SceneName.ARENA_SCENE:
                return 'ArenaScene';
            case SceneName.CLAN_SCENE:
                return 'ClanScene';
            case SceneName.LOAD_SCENE:
                return 'LoadScene';
            case SceneName.MUSIC_SCENE:
                return 'MusicScene';
            case SceneName.BOOT_FIX_SCENE:
                return 'BootFixScene';
            case SceneName.BATTLE_LOAD_SCENE:
                return 'BattleLoadScene';
            case SceneName.CLAN_LOAD_SCENE:
                return 'ClanLoadScene';
        }
    }

    public static reloadScene(): void {
        PlayerStore.fetchPlayer().then(() => {
            SceneService.sceneService.startScene(SceneUtils.getCurrentScene(), SceneName.ROAD_SCENE, CU.CANVAS_WIDTH, 2000);
        });
    }
}
