export enum UIStyle {
  Ordinary = "",
  NewYear = "_snow",
}

export enum TextStyle {
  Ordinary = "#aa2128",
  NewYear = "#9de0fb",
}

export enum  LoadingAnimStyle {
  Ordinary = "Ordinary",
  NewYear = "NewYear",
}
