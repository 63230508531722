import {Settings} from "@/constants/settings";

export function SpaceTr(key: string): string {
    return ' ' + Tr(key);
}

export function TrSpace(key: string): string {
    return Tr(key) + ' ';
}

export function SpaceTrSpace(key: string): string {
    return ' ' + Tr(key) + ' ';
}

export function Tr(key: string): string {
    if (!(key in Settings.LANG)) {
        console.log('ERROR: key ' + key + ' not found!!!');
        console.error('ERROR: key ' + key + ' not found!!!');
        return '???';
    }
    return Settings.LANG[key as keyof typeof Settings.LANG];
}

export function LocalizeClanStatus(clanStatus: string): string {      //hotfix
    switch (clanStatus) {
        case 'лидер'.toUpperCase():
            return Tr('lider').toUpperCase();
        case 'временн. лидер'.toUpperCase():
            return Tr('sublead').toUpperCase();
        case 'зам. лидера'.toUpperCase():
            return Tr('zamlead').toUpperCase();
        case 'представитель'.toUpperCase():
            return Tr('predstavitel').toUpperCase();
        case 'участник'.toUpperCase():
            return Tr('uchastnik').toUpperCase();
        case 'новичок'.toUpperCase():
            return Tr('novichok').toUpperCase();
        case 'НЕ ДОСТУПЕН': return "NOT AVAILABLE";
        default: return clanStatus;
    }
}

export function LocalizeBadgeName(badgeName: string): string {       //hotfix
    switch (badgeName) {
        case 'Крылатый кубок':  return "Winged cup";
        case 'Искатель ключей': return "Key finder";
        case 'Кубок славы': return "Cup of glory";
        case 'Кубок победителя':    return "Winner's Cup";
        case 'Победитель драконов': return "Winner of dragons";
        case 'Друг драконов':   return "Friend of the dragons";
        case 'Кубок воина': return "Warrior's cup";
        case 'Кубок воителя':   return "Superwarrior's cup";
        case 'Кубок истребителя':   return "Fighter's cup";
        case 'Кубок сокрушителя':   return "Crusher's cup";
        case 'Кубок бессмертного':  return "Immortal's cup";
        case 'Кубок завоевателя':   return "Conqueror's cup";
        case 'Кубок силы':  return "Cup of power";
        case 'Это уровень': return "This is the level!";
        case 'Высокий уровень': return "The high level!";
        case 'Заоблачный уровень':  return "Magnificent level";
        case 'Космический уровень': return "Space level";
        default: return badgeName;
    }
}