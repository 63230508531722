import { injectable } from 'inversify';
import ClanStore from "@/store/clan";
import PlayerStore from "@/store/player";
import PlaceStore from "@/store/place";
import CU from "@/utils/c-u";
import PortalStore from "@/store/portal";
import {WorldType} from "@/store/player/types";

@injectable()
export class PlaceService {

    public static readonly COORDINATES_RANGE_TO_QUERY: number = Math.round(CU.virtualWindowHeight() * 1.1);
    public static readonly COORDINATES_THREASHOLD: number = Math.round(CU.virtualWindowHeight() * 1.6);

    private static queriedTillCoordinate: number = 0;

    private static placesLoaded: boolean = false;

    private static requestPending: boolean = false;

    public static getMaxLoadedCoordinate() {
        return this.queriedTillCoordinate;
    }

    public static reset(): void {
        PlaceService.queriedTillCoordinate = 0;
        PlaceService.placesLoaded = false;
    }

    public startClanRaid(callback: () => unknown) {
        ClanStore.startClanRaid().then(() => {
            PlaceService.reset();
            callback();
        });
    }

    public endClanRaid(reset: boolean, callback: () => unknown) {
        ClanStore.endClanRaid(reset).then(() => {
            PlaceService.reset();
            callback();
        });
    }

    public usePortal(placeId: number, callback: () => unknown, discount?:number) {
        PortalStore.use(placeId, discount).then(() => {
            PlaceService.reset();
            callback();
        });
    }

    public turn(placeId: number, callback: () => unknown) {
        PlaceStore.turn(placeId).then(() => {
            PlaceService.reset();
            callback();
        });
    }

    public shiftPlayer(coordinate: number, loadCallback: () => unknown): void {
        coordinate = Math.floor(coordinate);
        PlayerStore.setCoordinate(coordinate);

        if (coordinate + PlaceService.COORDINATES_THREASHOLD > PlaceService.queriedTillCoordinate && !PlaceService.requestPending) {

            let coordFrom = Math.max(coordinate, PlaceService.queriedTillCoordinate);
            let coordTo = coordFrom + PlaceService.COORDINATES_RANGE_TO_QUERY;
            if (!PlaceService.placesLoaded) {
                coordTo += PlaceService.COORDINATES_RANGE_TO_QUERY;
            }
            PlaceService.requestPending = true;
            PlaceStore.fetchPlaces(coordinate, coordFrom, coordTo).then(() => {
                PlaceService.placesLoaded = true;
                PlaceService.queriedTillCoordinate = coordTo;
                PlaceService.requestPending = false;
                loadCallback();
            }).catch(() => {
                PlaceService.requestPending = false;
            });
        }
    }

    public isPlacesLoaded() {
        return PlaceService.placesLoaded;
    }
}
