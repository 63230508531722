import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import { isEqual } from 'lodash';
import http from '../../axios';
import { RatingsState, RatingsData, RatingsType, RatingsRequestParams, RatingsRequestToken } from './types';
import { RootState } from '../types';
import { Request } from '@/constants/request';
import { cancellable, CancellablePromise } from '@/utils/request-utils'
import {StatusDto} from "@/store/player/types";

const namespaced: boolean = false;
let requestToken: RatingsRequestToken | null = null

const state: RatingsState = {
  ratingsMeta: null,
  ratings: { type: RatingsType.PO_SILE, filter: '', list: [] },
  myRatingsPosition: null,
  ratingsListLength: 0
};

const getters: GetterTree<RatingsState, any> = {
  ratingsMeta: ({ ratingsMeta }) => ratingsMeta,
  ratingsList: ({ ratings }) => ratings.list,
  ratingsType: ({ ratings }) => ratings.type,
  ratingsFilter: ({ ratings }) => ratings.filter,
  myRatingsPosition: ({ myRatingsPosition }) => myRatingsPosition,
  ratingsListLength: ({ratingsListLength}) => ratingsListLength,
};

const mutations: MutationTree<RatingsState> = {
  SET_RATINGS_RESPONSE_DATA(st: RatingsState, payload: { data: RatingsData, params: RatingsRequestParams }) {
    const { data, params } = payload;
    if ( st.ratings.type !== params.type ) return
    const { playersTotal, playersOnline, myPosition, positions } = data;
    st.ratingsMeta = { playersTotal, playersOnline };
    st.myRatingsPosition = myPosition;
    // console.log("//////////////////////////////////")
    // console.log(st.ratings.list)
    st.ratings = {
      ...st.ratings,
      list: [...st.ratings.list, ...positions].sort((a, b) => a.positionNumber - b.positionNumber)
    };
    // console.log(st.ratings.list)
  },
  SET_RATINGS_STATE(st: RatingsState, payload: Partial<RatingsState>) {
    if (payload.ratings) st.ratings = payload.ratings;
    if (payload.ratingsMeta !== undefined) st.ratingsMeta = payload.ratingsMeta;
    if (payload.myRatingsPosition !== undefined) st.myRatingsPosition = payload.myRatingsPosition;
    st.ratingsListLength = 0
  },
  SET_RATINGS_LIST_LENGTH(state, newRatingsListLength) {
    state.ratingsListLength = newRatingsListLength;
  },
  SET_RATINGS_TYPE(state, type: RatingsType) {
    state.ratings.type = type;
  }
};

const actions: ActionTree<RatingsState, any> = {
  async getRatingsList({ commit, dispatch, getters }) {
    const reqParams = { type: getters.ratingsType, filter: getters.ratingsFilter }
    const get_: (p: RatingsRequestParams) => Promise<void> = async (reqParams) => {
      try {
        const data = await dispatch('fetchRatingsList', reqParams);
        commit('SET_RATINGS_RESPONSE_DATA', { data, params: reqParams });
        requestToken = null
      } catch(err) {
        if (requestToken && isEqual(requestToken.params, reqParams)) {
          requestToken.attemptsLeft -= 1;
          if (requestToken.attemptsLeft <= 0) {
            requestToken = null
            dispatch('getRatingsError', err);
            throw err;
          }
          return get_(reqParams);
        }
      }
    }
    if (requestToken !== null) requestToken.promise.cancel()
    const promise = get_(reqParams)
    requestToken = {
      params: { ...reqParams },
      attemptsLeft: 5,
      promise: cancellable(promise) as CancellablePromise<void>
    }
    return promise
  },
  fetchRatingsList({ getters, commit }, reqParams: RatingsRequestParams) {
    return new Promise((resolve, reject) => {
      const { filter, type } = reqParams;
      const endpoint = `/api/rating/by/${type}`;
      const ratingsLen = getters.ratingsListLength;
      const [from, to] = [ratingsLen + 1, ratingsLen + Request.POSITIONS_PER_PAGE];      
      commit('SET_RATINGS_LIST_LENGTH', getters.ratingsListLength + Request.POSITIONS_PER_PAGE);

      const params = {
        filter,
        from,
        to: filter ? to + 1 : to,
      };
      return http.get(endpoint, { params })
        .then(r => resolve(r.data as RatingsData))
        .catch(err => reject(err))
    })
  },
  setRatingsParams({ getters, commit, dispatch }, payload: { type?: RatingsType, filter?: string }) {
    const filter = payload.filter ?? getters.ratingsFilter
    const type = payload.type ?? getters.ratingsType
    if (getters.ratingsType === type && getters.ratingsFilter === filter) return
    const ratings = { type, filter, list: [] }
    commit('SET_RATINGS_STATE', { ratings })
    queueMicrotask(() => dispatch('getRatingsList'))
  },
  getRatingsError({ dispatch }, err: any) {
    console.error('vuex/ratings/getRatingsError:', { err })
    dispatch('clearRatings')
  },
  clearRatings({ commit }) {
    if (requestToken !== null) requestToken.promise.cancel()
    const ratings = {
      type: RatingsType.PO_SILE,
      filter: '',
      list: [],
    }
    commit('SET_RATINGS_STATE', { ratings, ratingsMeta: null, myRatingsPosition: null })
    requestToken = null
  }
}

export const ratingsModule: Module<RatingsState, RootState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions
};