import {Tr, TrSpace} from "@/i18n/i18n";
import PlayerStore from "@/store/player";

export default class TimeUtils {

    public static getDurationText(secRemain: number): string {
        if (secRemain < 60) {
            return String(secRemain) + Tr('sek');
        } else if (secRemain < 3600) {
            let mins = Math.floor(secRemain / 60);
            let sec = (secRemain - mins * 60);
            return String(mins) + TrSpace('minute.dot') + (sec != 0 ? (String(sec) + Tr('sec.dot')) : '');
        } else if (secRemain < 259200) {
            let hours = Math.floor(secRemain / 3600);
            let mins = Math.floor((secRemain - hours * 3600) / 60);
            let sec = (secRemain - hours * 3600 - mins * 60);
            return String(hours) + Tr('hour.dot') + ' ' + (mins != 0 ? String(mins) + TrSpace('minute.dot') : '') + (sec != 0 ? (String(sec) + Tr('sec.dot')) : '');
        }
        else {
            let days = Math.floor(secRemain / 86400);
            let hours = Math.floor((secRemain - days * 86400) / 3600);
            let mins = Math.floor((secRemain - days * 86400 - hours * 3600) / 60);
            let sec = (secRemain - days * 86400 - hours * 3600 - mins * 60);
            return String(days) + Tr('day.dot') + String(hours) + Tr('hour.dot') + ' ' + (mins != 0 ? String(mins) + TrSpace('minute.dot') : '') + (sec != 0 ? (String(sec) + Tr('sec.dot')) : '');
        }

    }



    public static getDurationTextSimple(secRemain: number): string {
        if (secRemain < 3600) {
            let mins = Math.floor(secRemain / 60);
            return String(mins) + 'M. ';
        } else {
            let hours = Math.floor(secRemain / 3600);
            let mins = Math.floor((secRemain - hours * 3600) / 60);
            return String(hours) + Tr('hour') + (mins != 0 ? String(mins) + 'M. ' : '');
        }
    }

    public static getDurationTextForTimer(secRemain: number): string {
        if (secRemain < 60) {
            return String(secRemain) + Tr('sek');
        } else if (secRemain < 3600) {
            let mins = Math.floor(secRemain / 60);
            let sec = (secRemain - mins * 60);
            return String(mins) + TrSpace('minute.dot') + (sec != 0 ? (String(sec) + Tr('sec.dot')) : '');
        } else if (secRemain < 259200) {
            let hours = Math.floor(secRemain / 3600);
            let mins = Math.floor((secRemain - hours * 3600) / 60);
            return String(hours) + Tr('hour.dot') + ' ' + (mins != 0 ? String(mins) + TrSpace('minute.dot') : '');
        }
        else {
            let days = Math.floor(secRemain / 86400);
            let hours = Math.floor((secRemain - days * 86400) / 3600);
            let mins = Math.floor((secRemain - days * 86400 - hours * 3600) / 60);
            return String(days) + Tr('day.dot') + String(hours) + Tr('hour.dot');
        }
    }

    public static getServerTimeMs() {
        return Date.now() + PlayerStore.getTimeDeltaMs();
    }

    public static logTime(txt: string) {
        let d: Date = new Date();
        console.log(txt + ': ' + d.toISOString());
    }

    public static remainSec(time: number): number {
        return Math.floor((time - TimeUtils.getServerTimeMs()) / 1000);
    }
}


