import {Tr} from "@/i18n/i18n";
import {SkillType} from "@/store/place/types";
import {Keys} from "@/constants/key";
import {Skill} from "@/store/level/types";
import PlayerStore from "@/store/player";

export default class SkillUtils {
    // @ts-ignore
    public static getSkillText(skillType: SkillType): string {
        switch (skillType) {
            case SkillType.ATTACK:
                return Tr('ataka');
            case SkillType.DEFENCE:
                return Tr('zaschita');
            case SkillType.HEALTH:
                return Tr('zdorove');
        }
    }

    public static getSkillIcon(skillType: SkillType): string {
        switch (skillType) {
            case SkillType.ATTACK:
                return Keys.IMG_ATTACK;
            case SkillType.DEFENCE:
                return Keys.IMG_DEFENCE;
            case SkillType.HEALTH:
                return Keys.IMG_HEART_ICON;
            case SkillType.POISON:
                return Keys.IMG_HEART_ICON;
            case SkillType.IMMORTALITY:
                return Keys.IMG_HEART_ICON;
        }
    }

    public static getSkill(skills: Skill[], skillType: SkillType): Skill {
        for (let skill of skills) {
            if (skill.skillType === skillType) {
                return skill;
            }
        }
        throw Error('Unknown skill');
    }

    public static getSkillValue(skills: Skill[], skillType: SkillType): number {
        return this.getSkill(skills, skillType).valueCurrent;
    }

    public static formatValue(value: number | undefined): string {
        if (!value) {
            return '';
        }
        value = Math.round(value);
        if (value > 9999) {
            if (value > 999999999) {
                if (value > 999999999999999999999999999999) {
                    return String((value / 1000000000000000000000000000000).toFixed((value % 1000000000000000000000000000000 == 0 || value >= 100000000000000000000000000000000) ? 0 : 1)) + 'Q';
                }
                if (value > 999999999999999999999999999) {
                    return String((value / 1000000000000000000000000000).toFixed((value % 1000000000000000000000000000 == 0 || value >= 100000000000000000000000000000) ? 0 : 1)) + 'R';
                }
                if (value > 999999999999999999999999) {
                    return String((value / 1000000000000000000000000).toFixed((value % 1000000000000000000000000 == 0 || value >= 100000000000000000000000000) ? 0 : 1)) + 'Y';
                }
                if (value > 999999999999999999999) {
                    return String((value / 1000000000000000000000).toFixed((value % 1000000000000000000000 == 0 || value >= 100000000000000000000000) ? 0 : 1)) + 'S';
                }
                if (value > 999999999999999999) {
                    return String((value / 1000000000000000000).toFixed((value % 1000000000000000000 == 0 || value >= 100000000000000000000) ? 0 : 1)) + 'E';
                }
                if (value > 999999999999999) {
                    return String((value / 1000000000000000).toFixed((value % 1000000000000000 == 0 || value >= 100000000000000000) ? 0 : 1)) + 'P';
                }
                if (value > 999999999999) {
                    return String((value / 1000000000000).toFixed((value % 1000000000000 == 0 || value >= 100000000000000) ? 0 : 1)) + 'T';
                }
            }
            if (value > 999999999) {
                return String ((value / 1000000000).toFixed((value % 1000000000 == 0 || value >= 100000000000) ? 0 : 1)) + 'G';
            }
            if (value > 999999) {
                return String ((value / 1000000).toFixed((value % 1000000 == 0 || value >= 100000000) ? 0 : 1)) + 'M';
            }
            if (value > 99999) {
                return String ((value / 1000).toFixed(0)) + 'k';
            }
            return String ((value / 1000).toFixed((value % 1000 == 0) ? 0 : 1)) + 'k';
        }
        return String(value);
    }

    public static formatMoreThanMillion(value: number): string {
        if (value > 999999) {
            return this.formatValue(value);
        }
        return String(value);
    }

    public static formatHealth(value: number): string {
        if (value > 9999) {
            if (value > 999999999999999999999) {
                return String ((value / 1000000000000000000000).toFixed(value % 1000000000000000000000 == 0 ? 0 : 1)) + 'S';
            }
            if (value > 999999999999999999) {
                return String ((value / 1000000000000000000).toFixed(value % 1000000000000000000 == 0 ? 0 : 1)) + 'E';
            }
            if (value > 999999999999999) {
                return String ((value / 1000000000000000).toFixed(value % 1000000000000000 == 0 ? 0 : 1)) + 'P';
            }
            if (value > 999999999999) {
                return String ((value / 1000000000000).toFixed(value % 1000000000000 == 0 ? 0 : 1)) + 'T';
            }
            if (value > 999999999) {
                return String ((value / 1000000000).toFixed(value % 1000000000 == 0 ? 0 : 1)) + 'G';
            }
            if (value > 999999) {
                return String ((value / 1000000).toFixed(value % 1000000 == 0 ? 0 : 1)) + 'M';
            }
            return String ((value / 1000).toFixed(value % 1000 == 0 ? 0 : 1)) + 'k';
        }
        return String(value);
    }

    public static getPileImage(skillType: SkillType) {
        switch (skillType) {
            case SkillType.ATTACK:
                return Keys.SK_ATTACK;
            case SkillType.DEFENCE:
                return Keys.SK_DEFENCE;
            case SkillType.HEALTH:
                return Keys.SK_HEALTH;
        }
        return Keys.SK_HEALTH;
    }
}
