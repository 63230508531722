import {ActionTree, Module, GetterTree, MutationTree} from "vuex";
import orderBy from 'lodash/orderBy';
import http from "@/axios";
import {RootState} from "@/store/types";
import {ChatState, MessageChat} from "./types";
import {MESSAGES_PER_FETCH} from './constants';

const namespaced: boolean = false;

const state: ChatState = {
  messages: [],
};

const getters: GetterTree<ChatState, any> = {
  chatMessages: ({ messages }) => messages,
  chatMessageIds: ({ messages }) => messages.map((m: MessageChat) => m.id),
  chatLatestMessage: ({ messages }) => messages.length && messages[0],
  chatEarliestMessage: ({ messages }) => messages.length && messages[messages.length -1],
};

const mutations: MutationTree<ChatState> = {
  SET_CHAT_RESPONSE_DATA(st: ChatState, payload: { data: MessageChat[] }) {
    const { data } = payload;
    const messages = [ ...st.messages, ...data ];
    st.messages = orderBy(messages, ['time'], ['desc']);
  },
  SET_CHAT_LATEST_MESSAGES(st: ChatState, payload: { data: MessageChat[] }) {
    st.messages = [...payload.data, ...st.messages];
  },
  CLEAR_CHAT_MESSAGES(st: ChatState) {
    st.messages = [];
  },
  DELETE_CHAT_MESSAGE(st: ChatState, id: number) {
    st.messages = st.messages.filter(m => m.id !== id);
  }
};

const actions: ActionTree<ChatState, any> = {

  fetchChatList({ commit, getters }, payload: { roomType: string }) {
    const { roomType } = payload;
    const { chatMessages, chatMessageIds } = getters;
    const endpoint = 'api/chat/' + roomType + '/list';
    const params = { from: 0, to: MESSAGES_PER_FETCH }
    if (chatMessages.length) {
      params.from = chatMessages.length;
      params.to = params.from + MESSAGES_PER_FETCH;
    }
    return http.get(endpoint, { params })
      .then(r => r.data)
      .then((data: MessageChat[]) => {
        const filteredMessages = data.filter((m: MessageChat) => {
          return !chatMessageIds.includes(m.id);
        });
        commit('SET_CHAT_RESPONSE_DATA', { data: filteredMessages });
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },

  fetchLatestChatMessages({commit, getters, dispatch}, payload: { roomType: string }) {
    const { roomType } = payload;
    const { chatLatestMessage } = getters;
    if (!chatLatestMessage) return dispatch('fetchChatList');
    const afterId = chatLatestMessage.id;
    const endpoint = 'api/chat/' + roomType + '/list/latest';
    const params = { afterId };
    return http.get(endpoint, { params })
      .then(r => r.data)
      .then((data: MessageChat[]) => {
        commit('SET_CHAT_LATEST_MESSAGES', { data });
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },

  clearChatMessages({ commit }, payload: { roomType: string }) {
    commit('CLEAR_CHAT_MESSAGES');
  },

  postChatMessage(store, payload: { roomType: string, text: string }) {
    const { roomType, text } = payload;
    const endpoint = 'api/chat/' + roomType + '/send';
    return http.post(endpoint, text);
  },

  deleteChatMessage({ commit }, payload: { roomType: string, id: number }) {
    const { roomType, id } = payload;
    return http.post('/api/chat/' + roomType + '/message/' + id + '/delete');
  }
}

export const chatModule: Module<ChatState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
