export default class OnceCaller {

    private static callTimeMsById: Map<string, number> = new Map<string, number>();

    public static doOnce(actionId: string, action: () => unknown, delay: number = 1500): void {
        let lastCallTime: number | undefined = OnceCaller.callTimeMsById.get(actionId);
        if (!lastCallTime || Date.now() - lastCallTime > delay) {
            action();
            OnceCaller.callTimeMsById.set(actionId, Date.now());
        }
    }

    public static doOnceWithPromise(actionId: string, action: () => unknown, delay: number = 1500): Promise<unknown> {
        return new Promise((resolve, reject) => {
            let lastCallTime: number | undefined = OnceCaller.callTimeMsById.get(actionId);
            if (!lastCallTime || Date.now() - lastCallTime > delay) {
                const result = action();
                OnceCaller.callTimeMsById.set(actionId, Date.now());
                resolve(result);
            } else {
                //reject(new Error("Action is rate-limited."));
            }
        });
    }

}
