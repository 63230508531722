import Scene = Phaser.Scene;

export default class SceneUtils {

    private static currentScene: Scene | undefined;

    public static setCurrentScene(currentScene: Scene): void {
        SceneUtils.currentScene = currentScene;
    }

    public static getCurrentScene() {
        return SceneUtils.currentScene!;
    }

    public static getCanvas() {
        return this.getCurrentScene().sys.game.canvas;
    }
}
