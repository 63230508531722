import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { chatModule } from './chat';
import { mailModule } from './mail';
import { ratingsModule } from './ratings';

Vue.use(Vuex);

/**
 * Main store with modules
 */
const store: StoreOptions<RootState> = {
  modules: {
    chatModule,
    ratingsModule,
    mailModule
  },
  strict: false
};

export default new Vuex.Store<RootState>(store);
