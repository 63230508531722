import http from "@/axios";

export default class ConnectionStore {

    public static checkServerConnection() {
        return http.get('/api/connection/isConnectionExists')
            .catch(function (error) {
                return Promise.reject(error);
            });
    }
}
