import http from "@/axios";
import {BattleState, BattleStatus, MovePrediction} from './types';
import {Player} from "@/store/player/types";
import {Creature} from "@/store/level/types";
import {CreatureUtils} from "@/utils/creature-utils";
import PlayerStore from "@/store/player";
import PlaceStore from "@/store/place";

export default class BattleStore {

    private static state: BattleState = {
        battleStatus: undefined
    }

    private static SET_BATTLE_STATUS(battleStatus: BattleStatus | undefined) {
        BattleStore.state.battleStatus = battleStatus;

        if (BattleStore.state.battleStatus && BattleStore.state.battleStatus.battle.creatures) {
            BattleStore.state.battleStatus.battle.creatures = BattleStore.state.battleStatus.battle.creatures.sort((a: Creature, b: Creature) => {
                return a.id - b.id;
            });
        }
    }

    public static getBattleStatus() {
        return BattleStore.state.battleStatus;
    }

    public static getMyCreatures() {
        if (!BattleStore.state.battleStatus) {
            return [];
        }
        return BattleStore.state.battleStatus!.battle.creatures.filter(c => c.playerId === PlayerStore.getPlayer().id);
    }

    public static getEnemyCreatures() {
        if (!BattleStore.state.battleStatus) {
            return [];
        }
        return BattleStore.state.battleStatus!.battle.creatures.filter(c => c.playerId !== PlayerStore.getPlayer().id);
    }

    public static getMyAliveCreatures() {
        return BattleStore.getMyCreatures().filter((c: Creature) => CreatureUtils.getUnitHealthCurrentValue(c) > 0);
    }

    public static getEnemyAliveCreatures() {
        return BattleStore.getEnemyCreatures().filter((c: Creature) => CreatureUtils.getUnitHealthCurrentValue(c) > 0);
    }

    public static getCreatureById(id: number) {
        if (!BattleStore.state.battleStatus) {
            return undefined;
        }
        let creatures: Creature[] = BattleStore.state.battleStatus!.battle.creatures.filter(c => c.id === id);
        return creatures.length > 0 ? creatures[0] : undefined;
    }

    public static attackPlace(placeId: number): Promise<BattleStatus> {
        var params = new URLSearchParams();
        params.append('placeId', String(placeId));

        return http.post('/api/battle/place/attack', params)
            .then(r => r.data)
            .then(battleStatus => {
                let bs: BattleStatus = Object.assign(new BattleStatus(), battleStatus);
                BattleStore.SET_BATTLE_STATUS(bs);
                return bs;
            });
    }

    public static attackArena(): Promise<BattleStatus> {
        return http.post('/api/battle/arena/attack')
            .then(r => r.data)
            .then(battleStatus => {
                let bs: BattleStatus = Object.assign(new BattleStatus(), battleStatus);
                BattleStore.SET_BATTLE_STATUS(bs);
                return bs;
            });
    }

    public static getBattle(): Promise<BattleStatus> {
        return http.post('/api/battle/get')
            .then(r => r.data)
            .then(battleStatus => {
                let bs: BattleStatus = Object.assign(new BattleStatus(), battleStatus);
                BattleStore.SET_BATTLE_STATUS(bs);
                return bs;
            });
    }

    public static ask(): Promise<BattleStatus | undefined> {
        return http.post('/api/battle/ask')
            .then(r => r.data)
            .then(battleStatus => {
                if (!battleStatus) {
                    return undefined;
                }
                let bs: BattleStatus = Object.assign(new BattleStatus(), battleStatus);
                BattleStore.SET_BATTLE_STATUS(bs);
                return bs;
            });
    }

    public static skip(): Promise<BattleStatus | undefined> {
        return http.post('/api/battle/skip')
            .then(r => r.data)
            .then(battleStatus => {
                if (!battleStatus) {
                    return undefined;
                }
                let bs: BattleStatus = Object.assign(new BattleStatus(), battleStatus);
                BattleStore.SET_BATTLE_STATUS(bs);
                return bs;
            });
    }

    public static hit(attackerId: number, defenderId: number): Promise<BattleStatus | undefined> {
        var params = new URLSearchParams();
        params.append('attackerId', String(attackerId));
        params.append('defenderId', String(defenderId));

        return http.post('/api/battle/hit', params)
            .then(r => r.data)
            .then(battleStatus => {
                if (!battleStatus) {
                    return undefined;
                }
                let bs: BattleStatus = Object.assign(new BattleStatus(), battleStatus);
                BattleStore.SET_BATTLE_STATUS(bs);
                return bs;
            });
    }

    public static precalculate(attackerId: number, defenderId: number): Promise<MovePrediction> {
        var params = new URLSearchParams();
        params.append('attackerId', String(attackerId));
        params.append('defenderId', String(defenderId));

        return http.post('/api/battle/precalculate', params)
            .then(r => r.data)
            .then(movePrediction => {
                return Object.assign(new MovePrediction(), movePrediction);
            });
    }

    public static complete(victory: boolean): Promise<void> {
        return http.post('/api/battle/complete')
            .then(r => r.data)
            .then((playerObj) => {
                let player: Player = Object.assign(new Player(), playerObj);
                if (BattleStore.state.battleStatus) {
                    if (victory) {
                        PlaceStore.REMOVE_CREATURE(BattleStore.state.battleStatus!.battle.placeId);
                    } else {
                        PlaceStore.SET_PLACE_BATTLE_LOST(BattleStore.state.battleStatus!.battle.placeId);
                    }
                }
                BattleStore.SET_BATTLE_STATUS(undefined);
                PlayerStore.SET_PLAYER(player);
            });
    }

    public static useConsumable(id: number, targetCreatureId: number): Promise<BattleStatus> {
        var params = new URLSearchParams();
        params.append('id', String(id));
        params.append('targetCreatureId', String(targetCreatureId));

        return http.post('/api/battle/removeConsumable', params)
            .then(r => r.data)
            .then(battleStatus => {
                let bs: BattleStatus = Object.assign(new BattleStatus(), battleStatus);
                BattleStore.SET_BATTLE_STATUS(bs);
                return bs;
            });
    }

    public static giveUpBattle(): Promise<void> {
        return http.post('/api/battle/giveUpBattle')
            .then(r => r.data)
            .then((battleStatus) => {
                BattleStore.SET_BATTLE_STATUS(battleStatus);
            });
    }

}
