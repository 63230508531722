import PlayerStore from "@/store/player";
import {Keys} from "@/constants/key";
import {Tr} from "@/i18n/i18n";
import TimeUtils from "@/utils/time-utils";
import {TaskConstants} from "@/constants/task-constants";
import {Lang} from "@/store/player/types";

export default class TaskUtils {

    public static ALL_EVENT_TASKS_WITH_PRIZES_PANEL = [TaskConstants.TASK_BIRTHDAY_HAT, TaskConstants.TASK_FERN,
        TaskConstants.TASK_APPLE, TaskConstants.TASK_BOOKS, TaskConstants.TASK_PUMPKIN];

    public static getEventCurrency(){
        // if(TaskUtils.isBirthdayHatTime()){
        //     let task = PlayerStore.getTaskById(TaskConstants.TASK_BIRTHDAY_HAT);
        //     if(task){
        //         return task.progress + task.total * (task.taskNumber - 1);
        //     }
        // } else if(TaskUtils.isIvanKupalaTime()){
        //     let task = PlayerStore.getTaskById(TaskConstants.TASK_FERN);
        //     if(task){
        //         return task.progress + task.total * (task.taskNumber - 1);
        //     }
        // } else if(TaskUtils.isAugustApplesTime()){
        //     let task = PlayerStore.getTaskById(TaskConstants.TASK_APPLE);
        //     if(task){
        //         return task.progress + task.total * (task.taskNumber - 1);
        //     }
        // }
        // if(TaskUtils.isSeptemberCatsTime()){
        //     let task = PlayerStore.getTaskById(TaskConstants.TASK_BOOKS);
        //     if(task){
        //         return task.progress + task.total * (task.taskNumber - 1);
        //     }
        // }

        if (TaskUtils.isHalloweenTime()) {
            let task = PlayerStore.getTaskById(TaskConstants.TASK_PUMPKIN);
            if(task){
                return task.progress + task.total * (task.taskNumber - 1);
            }
        }
        return 0;
    }

    public static getEventDescHeader(){
        // if(TaskUtils.isBirthdayHatTime()){
        //     return Tr('sbor.shliap');
        // } else if(TaskUtils.isIvanKupalaTime()){
        //     return Tr('sbor.paporotnika');
        // } else if(TaskUtils.isAugustApplesTime()){
        //     return Tr('by.ap');
        // }
        // if (TaskUtils.isSeptemberCatsTime()){
        //     return Tr('by.bk');
        // }
        if (TaskUtils.isHalloweenTime()){
            return Tr('by.pk');
        }
        return "";
    }

    public static isHalloweenTime(): boolean {
        let start = this.getDate(2024, 10, 28, 1, 0).getTime();
        let end = this.getDate(2024, 10, 30, 3, 59).getTime();

        let now = TimeUtils.getServerTimeMs();

        return start < now && now < end;
    }

    public static isEventRatingTime() {
        // return TaskUtils.isBirthdayHatTime() || TaskUtils.isIvanKupalaTime() || TaskUtils.isAugustApplesTime();
        // return TaskUtils.isSeptemberCatsTime();
        return TaskUtils.isHalloweenTime();
    }

    public static isSeptemberCatsTime() {
        let start = this.getDate(2024, 9, 7, 8, 0).getTime();
        let end = this.getDate(2024, 9, 9, 1, 0).getTime();
        let now = TimeUtils.getServerTimeMs();

        return start < now && now < end;
    }

    public static isAugustApplesTime() {
        let start = this.getDate(2024, 8, 24, 8, 0).getTime();
        let end = this.getDate(2024, 8, 26, 1, 0).getTime();
        let now = TimeUtils.getServerTimeMs();

        return start < now && now < end;
    }

    public static isBirthdayHatTime() {
        let start = this.getDate(2024, 4, 16, 0, 0).getTime();
        let end = this.getDate(2024, 4, 17, 23, 59).getTime();
        let now = TimeUtils.getServerTimeMs();

        return start < now && now < end;
    }

    public static isIvanKupalaTime() {
        if(PlayerStore.getPlayer().lang != Lang.RU){
            return false; // TODO добавить серверную переменную rusEvents в yml
        }
        let start = this.getDate(2024, 7, 5, 8, 0).getTime();
        let end = this.getDate(2024, 7, 7, 23, 59).getTime();
        let now = TimeUtils.getServerTimeMs();

        return start < now && now < end;
    }

    public static fromBirthdayHatToEasterTime() {
        let start = this.getDate(2024, 4, 4, 0, 0).getTime();
        let end = this.getDate(2024, 5, 4, 23, 59).getTime();
        let now = TimeUtils.getServerTimeMs();

        return start < now && now < end;
    }

    static getEventResourceAtlas() {
        if(TaskUtils.isBirthdayHatTime()){
            return Keys.ATLAS_PLACES_BIRTHDAY;
        }
        return "";
    }

    static getEventResourceImage():string {
        // if(TaskUtils.isBirthdayHatTime()){
        //     return "hat-small";
        // } else if(TaskUtils.isIvanKupalaTime()){
        //     return "fern-small";
        // } else if(TaskUtils.isAugustApplesTime()){
        //     return "task-apple";
        // }
        // if (TaskUtils.isSeptemberCatsTime()){
        //     return "task-books";
        // }
        if (TaskUtils.isHalloweenTime()){
            return "task-pumpkin";
        }
        return "";
    }

    static getEventRatingText():string {
        // if(TaskUtils.isBirthdayHatTime()){
        //     return Tr('po.shliapam');
        // } else if (TaskUtils.isIvanKupalaTime()){
        //     return Tr('po.paporotnikam');
        // } else if (TaskUtils.isAugustApplesTime()){
        //     return Tr('by.ap');
        // }
        // if (TaskUtils.isSeptemberCatsTime()){
        //     return Tr('by.bk');
        // }
        if (TaskUtils.isHalloweenTime()){
            return Tr('by.pk');
        }
        return "";
    }

    private static getDate(year: number, month: number, day: number, hour: number, minute: number) {
        // month is 0-indexed
        return new Date(year, month-1, day, hour, minute);
    }
    // public static boolean isEasterTime() {
    //     LocalDateTime start = LocalDateTime.of(2023, 4, 16, 0, 0);
    //     LocalDateTime end = LocalDateTime.of(2023, 4, 17, 23, 59);
    //
    //     LocalDateTime now = LocalDateTime.now();
    //
    //     return start.isBefore(now) && now.isBefore(end);
    // }
    //
    // public static boolean isIvanKupalaTime() {
    //     LocalDateTime start = LocalDateTime.of(2023, 7, 7, 8, 0);
    //     LocalDateTime end = LocalDateTime.of(2023, 7, 10, 23, 59);
    //
    //     LocalDateTime now = LocalDateTime.now();
    //
    //     return start.isBefore(now) && now.isBefore(end);
    // }
    // public static boolean isNovemberEnd() {
    //     LocalDateTime start = LocalDateTime.of(2023, 11, 30, 11, 0);
    //     LocalDateTime end = LocalDateTime.of(2023, 11, 30, 23, 59);
    //
    //     LocalDateTime now = LocalDateTime.now();
    //
    //     return start.isBefore(now) && now.isBefore(end);
    // }
    //

}
