import http from '../../axios';
import PlayerStore from "@/store/player";
import {Player} from "@/store/player/types";
import PlaceStore from "@/store/place";
import {PortalDto, PortalOffer} from "@/store/portal/types";

export default class PortalStore {

    public static offerInfo(): Promise<PortalOffer | undefined> {
        return http.get('/api/portal/offer/get')
            .then(r => r.data)
            .then((portalOfferObj) => {
                if (portalOfferObj) {
                    return Object.assign(new PortalOffer(), portalOfferObj);
                } else {
                    return undefined;
                }
            });
    }

    public static info(portalId: number): Promise<PortalDto> {
        return http.get('/api/portal/' + portalId + '/info')
            .then(r => r.data)
            .then((portalObj) => {
                return Object.assign(new PortalDto(), portalObj);
            });
    }
    public static markDiscountChosen(discount: number): Promise<void> {
        return http.get('/api/portal/mark/discount/chosen' + (discount ? '?discount=' + String(discount) : ''))
            .then(r => r.data)
            .then(() => {
                //do nothing
            });
    }

    public static use(portalId: number, discount?:number): Promise<void> {
        return http.post('/api/portal/' + portalId + '/use' + (discount ? '?discount=' + String(discount) : ''))
            .then(r => r.data)
            .then((playerObj) => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER_SILENT(player);
                PlaceStore.CLEAR_ALL_PLACES();
            });
    }
}
