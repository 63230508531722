import CDNUtils from "@/utils/cdn-utils";

if (CDNUtils.usingCDN()) {
  __webpack_public_path__ = CDNUtils.getStaticUrl(true);
}

import Vue from 'vue';
import App from './App.vue';
import store from './store';
import './registerServiceWorker';
import 'reflect-metadata';
// @ts-ignore
import Vuebar from 'vuebar';
// @ts-ignore
import VueDragscroll from 'vue-dragscroll';

Vue.use(Vuebar);
Vue.use(VueDragscroll);

new Vue({
  store,
  render: (h) => h(App)
}).$mount('#app');
