import {Balance, ClanParticipationState, Player} from "@/store/player/types";
import {Amount} from "@/model/amount";
import {AmountWithGears} from "@/model/amount-with-gears";
import {ResourceType} from "@/store/place/types";
import {WarriorClass} from "@/store/level/types";

export interface ClanState {
    clanInfo: ClanInfo | undefined;
    about: string | undefined;
}

export class InvitationDto {
    clanParticipationState!: ClanParticipationState;
    clanCreationPrice!: number;
    invitingClans!: ClanShortInfo[];
}

export class ClanShortInfo {
    id!: number;
    name!: string;
    level!: number;
    membersCurr!: number;
    membersMax!: number;
    positionNumber!: number;
    clanDefenceChance!: number;
    clanExpRatio!: number;
    raidDistance!: number;
    hasGuestForum!: boolean;
    gears!: number;
}

export class ClanMinInfo {
    level!: number;
    membersCurr!: number;
    membersMax!: number;
}

export class ClanInfo {
    clan!: Clan;
    me!: ParticipantInfo;
    membersCurr!: number;
    membersMax!: number;
    nextLevelExperience!: number;
    clanDefenceChance!: number;
    raidDistance!: number;
    clanExpRatio!: number;
    authorities!: ClanAuthority[];
}

export class ClanmanInfo {
    playerId!: number;
    name!: string;
    level!: number;
    heroesCount!: number;
    online!: boolean;
    lastActivityAt!: string;
    bestVictoryStair!: number;
    participantInfo: ParticipantInfo | undefined;
    clanControlActions!: ClanControlAction[];
}

export class ParticipantInfo {
    clanId: number | undefined;
    clanPosition!: ClanPosition;
    clanStat!: ClanStat;
    advertisementSeen!: boolean;
}

export class Clan {
    id!: number;
    name!: string;
    description!: string;
    level!: number;
    clanStat!: ClanStat;
    clanThemeNumber!: number;
    clanThemeChanges!: number;
    raidDistance!: number;
    advertisement!: string;
    advertisementAuthor!: string;
    gears!:number;
}

export class ClanTaskOption {
    raidDifficulty!: RaidDifficulty;
    count!: number;
    award!: Amount;
    current!: boolean;
}

export class ClanStat {
    experience!: number;
    battlesTotal!: number;
    gold!: number;
    redCrystal!: number;
    blueCrystal!: number;
    gems!: number;
}

export class ClanTaskInfo {
    itemId!: string;
    itemName!: string;
    countRequired!: number;
    countCurr!: number;
    raidState!: RaidState;
    raidOpensAt!: number;
    speedupPrice!: number;
    hoursGap!: number;
    clanAward!: Amount;
    personalAward!: Amount;
    personalAwardsCount!: number;
    taskPerformers!: ClanTaskPerformer[];
    balance!: Balance;

    raidStartTime!: string;
    raidEndTime!: string;
    nowTime!: string;
}

export class ClanBuildingInfo {
    name!: string;
    buildingType!: BuildingType;
    buildingState!: BuildingState;
    levelCurr!: number;
    levelMax!: number;
    releaseAt!: number;
    price!: Amount;
    updatingHours!: number;
    bonus!: number;
    bonusDelta!: number;
    clanBudget!: Amount;
    canStartBuilding!: boolean;
    canCompleteBuilding!: boolean;

    clanDefenceChance!: number;
    clanExpRatio!: number;
    distance!: number;
}

export class HallOptionInfo {
    force!: WarriorClass;
    value!: number;
    delta!: number;
    level!: number;
    maxLevel!: number;
    price!: AmountWithGears;
}

export class ForceImprovementDto {
    force!: WarriorClass;
    value!: number;
}

export class ClanTaskPerformer {
    itemsCount!: number;
    itemsPercent!: string;
    clanmanInfo!: ClanmanInfo;
}

export class DepositInfo {
    clanInfo!: ClanInfo;
    player!: Player;
}

export class DepositDto {
    depositPortions!: DepositPortion[];

    constructor() {
        this.depositPortions = [];
    }
}

export class DepositPortion {
    type!: ResourceType;
    amount!: number;

    constructor(type: ResourceType, amount: number) {
        this.type = type;
        this.amount = amount;
    }
}

export class ClanLogsDto {
    logs!:ClanLogDto[];
    pageCurr!: number;
    pageMax!: number;
}

export class ClanLogDto {
    clanAction!:ClanAction;
    playerName!: string;
    victimName!: string;
    details!: string;
    loggedAt!: string;
    count!: number;
}

export class ClanCandidate {
    playerId!: number;
    name!: string;
    level!: number;
    heroesCount!: number;
    bestVictoryCreatureId!: string;
    bestVictoryStair!: number;
    online!: boolean;
    lastActivityAt!: string;
    state!: ClanCandidateState;
}

export class FactoryGiftDto {
    count!: number;
    giftType!: FactoryGiftType;
}

export enum FactoryGiftType {
    GEMS = 'GEMS',
    GEARS   = 'GEARS',
    RED_CRYSTALS = 'RED_CRYSTALS',
    BLUE_CRYSTALS = 'BLUE_CRYSTALS',
    TICKETS = 'TICKETS'
}
export enum ClanPosition {
    LEADER = 'LEADER',
    SUBLEADER = 'SUBLEADER',
    DEPUTY_LEADER = 'DEPUTY_LEADER',
    REPRESENTATIVE = 'REPRESENTATIVE',
    PARTICIPANT = 'PARTICIPANT',
    NEWBIE = 'NEWBIE',
}

export enum ClanControlAction {
    INVITE = 'INVITE',
    KICK = 'KICK',
    UP_TO_PARTICIPANT = 'UP_TO_PARTICIPANT',
    UP_TO_REPRESENTATIVE = 'UP_TO_REPRESENTATIVE',
    UP_TO_DEPUTY = 'UP_TO_DEPUTY',
    DOWN_TO_REPRESENTATIVE = 'DOWN_TO_REPRESENTATIVE',
    DOWN_TO_PARTICIPANT = 'DOWN_TO_PARTICIPANT',
    DOWN_TO_NEWBIE = 'DOWN_TO_NEWBIE'
}

export enum RaidState {
    IN_RAID = 'IN_RAID',
    RAID_AVAILABLE = 'RAID_AVAILABLE',
    RAID_WAITING = 'RAID_WAITING',
    RAID_CLOSED = 'RAID_CLOSED'
}

export enum BuildingType {
    EXPERIENCE = 'EXPERIENCE',
    CLAN_SHIELD = 'CLAN_SHIELD',
    SNOW_WORLD = 'SNOW_WORLD',
    GEAR_FACTORY = 'GEAR_FACTORY',
    HALL_OF_FAME = 'HALL_OF_FAME'
}

export enum BuildingState {
    PROCESSING = 'PROCESSING',
    NORMAL = 'NORMAL'
}

export enum ClanCandidateState {
    WANTS_TO_THE_CLAN = 'WANTS_TO_THE_CLAN',
    WANTS_TO_ANY_CLAN = 'WANTS_TO_ANY_CLAN',
    REJECTED = 'REJECTED',
    INVITED = 'INVITED'
}

export enum ClanAction {
    invite = 'invite',
    joined = 'joined',
    reject = 'reject',
    kick = 'kick',
    left = 'left',
    raise = 'raise',
    subsidence = 'subsidence',
    goldDonated = 'goldDonated',
    redCrystalDonated = 'redCrystalDonated',
    blueCrystalDonated = 'blueCrystalDonated',
    gemsDonated = 'gemsDonated',
    gearsDonated = 'gearsDonated',
    leaderReplace = 'leaderReplace',
    created = 'created',
    subLeader = 'subLeader',
    upgradeWallOfFame = 'upgradeWallOfFame',
    changeDescription = 'changeDescription',
    changeTheme = 'changeTheme',
    giveAdvertisement = 'giveAdvertisement',
    changeDifficulty = 'changeDifficulty'
}


export enum ClanAuthority {
     INVITE = 'INVITE',
     RESIGN = 'RESIGN',
     LEAVE_CLAN = 'LEAVE_CLAN',
     UP_TO_DEPUTY_LEADER = 'UP_TO_DEPUTY_LEADER',
     DOWN_TO_REPRESENTATIVE = 'DOWN_TO_REPRESENTATIVE',
     UP_TO_REPRESENTATIVE = 'UP_TO_REPRESENTATIVE',
     UP_TO_PARTICIPANT = 'UP_TO_PARTICIPANT',
     DOWN_TO_PARTICIPANT = 'DOWN_TO_PARTICIPANT',
     DOWN_TO_NEWBIE = 'DOWN_TO_NEWBIE',
     KICK_PARTICIPANT = 'KICK_PARTICIPANT',
     KICK_NEWBIE = 'KICK_NEWBIE',
     KICK_REPRESENTATIVE = 'KICK_REPRESENTATIVE',
     START_BUILDING = 'START_BUILDING',
     COMPLETE_BUILDING = 'COMPLETE_BUILDING',
     EDIT_ABOUT = 'EDIT_ABOUT',
     EDIT_ADVERTISEMENT = 'EDIT_ADVERTISEMENT',
     CHANGE_RAID_DIFFICULTY = 'CHANGE_RAID_DIFFICULTY'
}

export enum RaidDifficulty {
    EASY = 'EASY',
    NORMAL = 'NORMAL',
    HARD = 'HARD',
    EXTREME = 'EXTREME'
}


