
import { Component, Vue } from 'vue-property-decorator';
import MainView from './views/MainView.vue';

@Component({
  components: {
    MainView,
  }
})
// App goes here
export default class App extends Vue {}
