export abstract class Http {
    static AUTHORIZATION: string | null = '';
    static JSESSION: string | null = '';

    static YA_UNIQUE_ID: string | null = '';

    static MYSTORE_UID: string | null = '';

    static CRAZY_ID: string | null = '';

    static KONG_ID: string | null = '';
}
