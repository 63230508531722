export enum SceneName {
    ROAD_SCENE,
    BATTLE_SCENE,
    CHAR_SELECTION_SCENE,
    CREATURE_SCENE,
    BOOT_SCENE,
    ARENA_SCENE,
    CLAN_SCENE,
    LOAD_SCENE,
    MUSIC_SCENE,
    BOOT_FIX_SCENE,
    BATTLE_LOAD_SCENE,
    CLAN_LOAD_SCENE,
    CREATURE_LOAD_SCENE
}
