import {Tr} from "@/i18n/i18n";
import {EffectType} from "@/store/artifact/types";
import Scene = Phaser.Scene;
import {Creature} from "@/store/level/types";
import PlayerStore from "@/store/player";
import SkillUtils from "@/utils/skill-utils";
import {ArenaLeague} from "@/store/arena/types";
import {Keys} from "@/constants/key";

export default class ArtifactUtils {

    public static readonly ARTIFACTS_RANGE_TO_LOAD = 3;

    public static loadArtifacts(scene: Scene): void {
        for (let a of PlayerStore.getArtifactIdsToLoad()) {
            if (!scene.textures.exists(a.atlasId)) {
                scene.load.atlas(a.atlasId, `assets/artifacts/${a.atlasId}.png`, `assets/artifacts/${a.atlasId}.json`);
            }
        }
        PlayerStore.SET_NEED_DOWNLOAD_IMAGES(false);
    }

    public static loadArtifactsByCreatures(scene: Scene, creatures: Creature[]): void {
        creatures.forEach(c => {
            c.artifacts.forEach(a => {
                if (!scene.textures.exists(a.atlasKey)) {
                    scene.load.atlas(a.atlasKey, `assets/artifacts/${a.atlasKey}.png`, `assets/artifacts/${a.atlasKey}.json`);
                }
            })
        })
    }


    public static getEffectText(effectType: EffectType, effectValue: number): string {
        return this.getEffectTypeText(effectType) + ' +' + SkillUtils.formatMoreThanMillion(effectValue);
    }

    public static getEffectTypeText(et: EffectType): string | undefined {
        switch (et) {
            case EffectType.ATTACK:
                return Tr('ataka');
            case EffectType.DEFENCE:
                return Tr('zaschita');
            case EffectType.ATTACK_DEFENCE:
                return Tr('ataka.i.zaschita');
            case EffectType.HEALTH:
                return Tr('zdorove');
        }
    }

    public static getEffectTypeGenetive(et: EffectType): string {
        switch (et) {
            case EffectType.ATTACK:
                return Tr('ataki');
            case EffectType.DEFENCE:
                return Tr('zaschiti');
            case EffectType.ATTACK_DEFENCE:
                return Tr('ataki.i.zaschiti');
        }
        return '';
    }
}
