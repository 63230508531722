import SoundConfig = Phaser.Types.Sound.SoundConfig;
import Scene = Phaser.Scene;
import BaseSound = Phaser.Sound.BaseSound;
import SceneUtils from "@/utils/scene-utils";

export default class SoundUtils {

    public static soundOff() {
        // if (SceneUtils.getCurrentScene()) {
        //     SceneUtils.getCurrentScene().game.sound.mute = true;
        // }
        [].slice.call(document.querySelectorAll('audio')).forEach(function(audio) {
            // @ts-ignore
            audio.muted = true;
        });
    }

    public static soundOn() {
        // if (SceneUtils.getCurrentScene()) {
        //     SceneUtils.getCurrentScene().game.sound.mute = false;
        // }
        [].slice.call(document.querySelectorAll('audio')).forEach(function(audio) {
            // @ts-ignore
            audio.muted = false;
        });
    }

    public static setLooped(sound: BaseSound | undefined) {
        if (sound) {
            // @ts-ignore
            sound.loop = true;
        }
    }

    public static play(sound: BaseSound | undefined) {
        try {
            if (sound) {
                sound.play();
            }
        } catch (e) {
            console.log('catched play sound exception');
        }
    }

    public static stop(sound: BaseSound | undefined) {
        try {
            if (sound) {
                sound.stop();
            }
        } catch (e) {
            console.log('catched stop sound exception');
        }
    }

    public static addAndPlay(scene: Scene, key: string, config: SoundConfig | undefined = undefined) {
        let sound = SoundUtils.addSound(scene, key, config);
        SoundUtils.play(sound);
    }

    public static addSound(scene: Scene, key: string, config: SoundConfig | undefined = undefined) {
        try {
            let res = scene.sound.get(key);
            if (res) {
                return res;
            }

            if (config) {
                return scene.sound.add(key, config);
            } else {
                return scene.sound.add(key);
            }
        } catch (e) {
            // console.log('catched sound exception during adding');
            // console.log(e);
            return undefined;
        }
    }

    public static getAttackSoundByPower(punchSounds: (Phaser.Sound.BaseSound | undefined)[], attackValue: number) {
        //Возвращает звук удара, в зависимости от силы атаки
        if (attackValue < 50) { return punchSounds[0]; }
        if (attackValue >= 50 && attackValue < 100) { return punchSounds[1]; }
        if (attackValue >= 100) { return punchSounds[2]; }
    }
}
