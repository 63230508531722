export abstract class TaskConstants {

    public static readonly TASK_EASTER_EGG: string = 'task-easter-egg';
    public static readonly TASK_FERN: string = 'task-fern';
    public static readonly TASK_APPLE: string = 'task-apple';
    public static readonly TASK_BOOKS: string = 'task-books';
    public static readonly TASK_PUMPKIN: string = 'task-pumpkin';
    public static readonly TASK_BIRTHDAY_HAT: string = 'task-birthday-hat';
    public static readonly TASK_BIRDS_FOR_MUSHROOM: string = 'sdq-bird1';
    public static readonly TASK_FLOWER_FOR_WREATH: string = 'sdq-flower3';

    public static readonly ALL_EVENT_TASKS: string[] = [
        TaskConstants.TASK_EASTER_EGG,
        TaskConstants.TASK_FERN,
        TaskConstants.TASK_APPLE,
        TaskConstants.TASK_BOOKS,
        TaskConstants.TASK_PUMPKIN,
        TaskConstants.TASK_BIRTHDAY_HAT
    ];
}