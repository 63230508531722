import http from '../../axios';
import {
    BuildingType,
    ClanBuildingInfo, ClanCandidate, ClanControlAction,
    ClanInfo, ClanLogDto, ClanLogsDto,
    ClanmanInfo, ClanMinInfo, ClanShortInfo,
    ClanState,
    ClanTaskInfo, ClanTaskOption, DepositDto, DepositInfo, FactoryGiftDto, HallOptionInfo,
    InvitationDto, RaidDifficulty
} from "@/store/clan/types";
import {HistoryDto, Player} from "@/store/player/types";
import ListenerManager from "@/managers/listener-manager";
import {Amount} from "@/model/amount";
import PlayerStore from "@/store/player";
import PlaceStore from "@/store/place";
import {WarriorClass} from "@/store/level/types";

export default class ClanStore {

    private static state: ClanState = {
        clanInfo: undefined,
        about: undefined,
    };

    private static SET_CLAN_ABOUT(ab: string) {
        ClanStore.state.about = ab;
    }

    private static SET_CLAN_INFO(clanInfo: ClanInfo | undefined) {
        ClanStore.state.clanInfo = clanInfo;
        ListenerManager.getClanInfoListeners().forEach(l => l.onChange());
    }

    private static SET_CLAN_BALANCE(amount: Amount) {
        if (ClanStore.state.clanInfo) {
            let s = ClanStore.state.clanInfo.clan.clanStat;
            s.gold = amount.gold;
            s.gems = amount.mithril;
            s.redCrystal = amount.crystal;
            s.blueCrystal = amount.elixir;
            ListenerManager.getClanInfoListeners().forEach(l => l.onChange());
        }
    }

    public static getClanInfo() {
        return ClanStore.state.clanInfo;
    }

    public static getClanAbout() {
        return ClanStore.state.about;
    }

    public static fetchClanInvitation(): Promise<InvitationDto> {
        return http.get('/api/clan/invitation/get')
            .then(r => r.data)
            .then(invObj => {
                let invitation: InvitationDto = Object.assign(new InvitationDto(), invObj);
                PlayerStore.SET_CLAN_STATE(invitation.clanParticipationState);
                PlayerStore.SET_PROMPT_CLAN(false);
                return invitation;
            });
    }

    public static fetchClanMembers(): Promise<ClanmanInfo[]> {
        return http.get('/api/clan/participant/list/mine')
            .then(r => r.data)
            .then(pObj => {
                let res: ClanmanInfo[] = [];
                for (let obj of pObj) {
                    let sc = Object.assign(new ClanmanInfo(), obj);
                    res.push(sc);
                }
                return res;
            });
    }

    public static fetchMembersByClanId(clanId: number): Promise<ClanmanInfo[]> {
        return http.get('/api/clan/participant/list?clanId=' + String(clanId))
            .then(r => r.data)
            .then(pObj => {
                let res: ClanmanInfo[] = [];
                for (let obj of pObj) {
                    let sc = Object.assign(new ClanmanInfo(), obj);
                    res.push(sc);
                }
                return res;
            });
    }

    public static applyClanBroadcast(): Promise<void> {
        return http.post('/api/clan/broadcast/apply');
    }

    public static inviteToClan(playerId: number): Promise<ClanMinInfo> {
        var params = new URLSearchParams();
        params.append('playerId', String(playerId));
        return http.post('/api/clan/invite', params).then(r => r.data).then((cmi: ClanMinInfo) => {
            if (ClanStore.state && ClanStore.state.clanInfo) {
                ClanStore.state.clanInfo.membersCurr = cmi.membersCurr;
            }
            return cmi;
        });
    }

    public static rejectApplication(playerId: number): Promise<ClanMinInfo> {
        var params = new URLSearchParams();
        params.append('playerId', String(playerId));
        return http.post('/api/clan/reject', params).then(r => r.data).then((cmi: ClanMinInfo) => {
            if (ClanStore.state && ClanStore.state.clanInfo) {
                ClanStore.state.clanInfo.membersCurr = cmi.membersCurr;
            }
            return cmi;
        });
    }

    public static applyClan(clanId: number): Promise<void> {
        var params = new URLSearchParams();
        params.append('clanId', String(clanId));
        return http.post('/api/clan/apply', params);
    }

    public static acceptClanInvitation(clanId: number): Promise<ClanInfo> {
        var params = new URLSearchParams();
        params.append('clanId', String(clanId));
        return http.post('/api/clan/invitation/accept', params)
            .then(r => r.data)
            .then(clanInfoObj => {
                let clanInfo: ClanInfo = Object.assign(new ClanInfo(), clanInfoObj);
                ClanStore.SET_CLAN_INFO(clanInfo);
                PlayerStore.SET_CLAN_ID(clanInfo.clan.id);
                return clanInfo;
            });
    }

    public static fetchMyClan(): Promise<ClanInfo | undefined> {
        return http.post('/api/clan/get/my')
            .then(r => r.data)
            .then(clanInfoObj => {
                if (clanInfoObj) {
                    let clanInfo: ClanInfo = Object.assign(new ClanInfo(), clanInfoObj);
                    ClanStore.SET_CLAN_INFO(clanInfo);
                    PlayerStore.SET_CLAN_ID(clanInfo.clan.id);
                    return clanInfo;
                } else {
                    ClanStore.SET_CLAN_INFO(undefined);
                    PlayerStore.SET_CLAN_ID(undefined);
                    return undefined;
                }
            });
    }

    public static createClan(clanName: string): Promise<ClanInfo> {
        var params = new URLSearchParams();
        params.append('name', clanName);

        return http.post('/api/clan/create', params)
            .then(r => r.data)
            .then(clanInfoObj => {
                let clanInfo: ClanInfo = Object.assign(new ClanInfo(), clanInfoObj);
                ClanStore.SET_CLAN_INFO(clanInfo);
                PlayerStore.SET_CLAN_ID(clanInfo.clan.id);
                return clanInfo;
            });
    }

    public static resignFromClan(): Promise<void> {
        return http.post('/api/clan/resign');
    }

    public static leaveClan(): Promise<void> {
        return http.post('/api/clan/leave');
    }

    public static participantAction(playerId: number, clanParticipantAction: ClanControlAction): Promise<ClanmanInfo> {
        var params = new URLSearchParams();
        params.append('playerId', String(playerId));
        params.append('type', clanParticipantAction);

        return http.post('/api/clan/participant/action', params)
            .then(r => r.data)
            .then(obj => {
                let clanmanInfo: ClanmanInfo = Object.assign(new ClanmanInfo(), obj);
                return clanmanInfo;
            });
    }

    public static startClanRaid(): Promise<void> {
        return http.post('/api/clan/raid/start')
            .then(r => r.data)
            .then((playerObj) => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER_SILENT(player);
                PlaceStore.CLEAR_ALL_PLACES();
            });
    }

    public static endClanRaid(reset: boolean): Promise<void> {
        var params = new URLSearchParams();
        params.append('reset', String(reset));
        return http.post('/api/clan/raid/end', params)
            .then(r => r.data)
            .then((playerObj) => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER_SILENT(player);
                PlaceStore.CLEAR_ALL_PLACES();
            });
    }

    public static speedupClanRaid(): Promise<ClanTaskInfo> {
        return http.post('/api/clan/raid/speedup')
            .then(r => r.data)
            .then(clanTaskInfoObj => {
                let clanTaskInfo: ClanTaskInfo = Object.assign(new ClanTaskInfo(), clanTaskInfoObj);
                PlayerStore.SET_BALANCE(clanTaskInfo.balance);
                return clanTaskInfo;
            });
    }

    public static fetchClanTaskInfo(): Promise<ClanTaskInfo> {
        return http.get('/api/clan/raid/get')
            .then(r => r.data)
            .then(clanTaskInfoObj => {
                let clanTaskInfo: ClanTaskInfo = Object.assign(new ClanTaskInfo(), clanTaskInfoObj);
                return clanTaskInfo;
            });
    }

    public static depositValute(depositDto: DepositDto): Promise<DepositInfo> {
        return http.post('/api/clan/donate', depositDto)
            .then(r => r.data)
            .then(depositInfoObj => {
                let depositInfo: DepositInfo = Object.assign(new DepositInfo(), depositInfoObj);
                PlayerStore.SET_PLAYER(depositInfo.player);
                ClanStore.SET_CLAN_INFO(depositInfo.clanInfo);
                return depositInfo;
            });
    }

    public static fetchClanRating(): Promise<ClanShortInfo[]> {
        return http.get('/api/clan/rating')
            .then(r => r.data)
            .then(rObj => {
                let clanShortInfo: ClanShortInfo[] = Object.assign([], rObj);
                return clanShortInfo;
            });
    }

    public static fetchClanInfo(playerId:number): Promise<ClanShortInfo|undefined> {
        var params = new URLSearchParams();
        params.append('playerId', String(playerId));
        return http.post('/api/clan/info', params)
            .then(r => r.data)
            .then(rObj => {
                if (rObj){
                    let clanShortInfo: ClanShortInfo = Object.assign([], rObj);
                    return clanShortInfo;
                }
                return undefined;
            });
    }

    public static fetchClanBuildings(): Promise<ClanBuildingInfo[]> {
        return http.get('/api/clan/building/list')
            .then(r => r.data)
            .then(pObj => {
                let res: ClanBuildingInfo[] = [];
                for (let obj of pObj) {
                    let sc = Object.assign(new ClanBuildingInfo(), obj);
                    res.push(sc);
                }
                return res;
            });
    }

    public static startClanBuilding(buildingType: BuildingType): Promise<ClanBuildingInfo> {
        var params = new URLSearchParams();
        params.append('buildingType', buildingType);

        return http.post('/api/clan/building/start', params)
            .then(r => r.data)
            .then((bObj) => {
                let res: ClanBuildingInfo = Object.assign(new ClanBuildingInfo(), bObj);
                ClanStore.SET_CLAN_BALANCE(res.clanBudget);
                return res;
            });
    }

    public static speedupClanBuilding(buildingType: BuildingType): Promise<ClanBuildingInfo> {
        var params = new URLSearchParams();
        params.append('buildingType', buildingType);

        return http.post('/api/clan/building/speedup', params)
            .then(r => r.data)
            .then((bObj) => {
                let res: ClanBuildingInfo = Object.assign(new ClanBuildingInfo(), bObj);
                ClanStore.SET_CLAN_BALANCE(res.clanBudget);
                return res;
            });
    }

    public static completeClanBuilding(buildingType: BuildingType): Promise<ClanBuildingInfo> {
        var params = new URLSearchParams();
        params.append('buildingType', buildingType);

        return http.post('/api/clan/building/complete', params)
            .then(r => r.data)
            .then((bObj) => {
                return Object.assign(new ClanBuildingInfo(), bObj);
            });
    }

    public static fetchClanCandidates(): Promise<ClanCandidate[]> {
        return http.get('/api/clan/recruit/list')
            .then(r => r.data)
            .then(pObj => {
                let res: ClanCandidate[] = [];
                for (let obj of pObj) {
                    let sc = Object.assign(new ClanCandidate(), obj);
                    res.push(sc);
                }
                return res;
            });
    }

    public static fetchClanRaidOptions(): Promise<ClanTaskOption[]> {
        return http.get('/api/clan/raid/options')
            .then(r => r.data)
            .then(pObj => {
                let res: ClanTaskOption[] = [];
                for (let obj of pObj) {
                    let sc = Object.assign(new ClanTaskOption(), obj);
                    res.push(sc);
                }
                return res;
            });
    }

    public static fetchFactoryGift(): Promise<FactoryGiftDto> {
        return http.get('/api/clan/factory/gift/get')
            .then(r => r.data)
            .then(pObj => {
                return Object.assign(new FactoryGiftDto(), pObj);
            });
    }

    public static takeFactoryGift(): Promise<Number> {
        return http.get('/api/clan/factory/gift/take')
            .then(r => r.data)
            .then(pObj => {
                return new Number(pObj);
            });
    }

    public static fetchClanAbout(): Promise<string> {
        return http.get('/api/clan/about/get').then(r => r.data).then(txt => {
            ClanStore.SET_CLAN_ABOUT(txt);
            return txt;
        });
    }

    public static setClanAbout(content: string): Promise<void> {
        var params = new URLSearchParams();
        params.append('content', content);

        return http.post('/api/clan/about/set', params).then(() => {
            ClanStore.SET_CLAN_ABOUT(content);
        });
    }

    public static setClanAdvertisement(content: string): Promise<void> {
        var params = new URLSearchParams();
        params.append('content', content);
        return http.post('/api/clan/advertisement/set', params);
    }

    public static setClanName(clanId: number, content: string): Promise<void> {
        var params = new URLSearchParams();
        params.append('name', content);
        params.append('clanId', clanId.toString());
        return  http.post('/api/admin/clan/name/set', params).then(() => {
            //do nothing
        });
    }

    public static markClanAdvertisementSeen(): Promise<void> {
        return http.get('/api/clan/advertisement/mark/seen');
    }

    public static restoreDefaultClanAbout(): Promise<void> {
        return http.post('/api/clan/about/default/restore').then(r => r.data).then(txt => {
            ClanStore.SET_CLAN_ABOUT(txt);
            return txt;
        });
    }

    public static setClanRaidDifficulty(raidDifficulty: RaidDifficulty): Promise<void> {
        var params = new URLSearchParams();
        params.append('raidDifficulty', raidDifficulty);
        return http.post('/api/clan/raid/difficulty/set', params);
    }

    public static setClanTheme(themeNumber: number): Promise<ClanInfo> {
        return http.post('/api/clan/theme/set?number=' + themeNumber)
            .then(r => r.data)
            .then(clanInfoObj => {
                let clanInfo: ClanInfo = Object.assign(new ClanInfo(), clanInfoObj);
                ClanStore.SET_CLAN_INFO(clanInfo);
                return clanInfo;
            });
    }

    public static fetchHallUpgradeOptions(): Promise<HallOptionInfo[]> {
        return http.get('/api/clan/hall/options')
            .then(r => r.data)
            .then(pObj => {
                let res: HallOptionInfo[] = [];
                for (let obj of pObj) {
                    let sc = Object.assign(new HallOptionInfo(), obj);
                    res.push(sc);
                }
                return res;
            });
    }

    public static makeForceImprovement(warriorClass:WarriorClass, level:number): Promise<Number> {
        var params = new URLSearchParams();
        params.append('force', warriorClass);
        params.append('toLevel', level.toString());
        return http.post('/api/clan/hall/improve', params)
            .then(r => r.data)
            .then(pObj => {
                return new Number(pObj);
            });
    }

    public static getClanLogs(clanId: number, page: number): Promise<ClanLogsDto> {
        return http.get('/api/clan/' + String(clanId) + '/log?page=' + String(page))
            .then(r => r.data)
            .then(rObj => {
                return Object.assign(new ClanLogDto(), rObj);
            });
    }


}
