import {Amount} from "@/model/amount";

export class PortalDto {
    placeId!: number;
    name!: string;
    desc!: string;
    atlasKey!: string;
    imageKey!: string;
    price!: Amount;
    length!: number;
    discount!: DiscountDto;
    activatableDiscount!: number;
}

export class PortalOffer {
    portalId!: string;
    price!: number;
    name!: string;
    nameAccusative!: string;
    atlasKey!: string;
    imageKey!: string;
}

class DiscountDto {
    oldPrice!: Amount;
    fromDate!: string;
    toDate!: string;
}
