import {RU} from "@/i18n/ru";
import {Lang, LangDescription, LangProp, LocationType} from "@/store/player/types";
import {EN} from "@/i18n/en";
import {IntegrationUtils} from "@/utils/integration-utils";
import PlayerStore from "@/store/player";

export abstract class Settings {

    public static readonly MAX_CREATURES = 6;

    public static readonly MUSIC_VOLUME: number = 0.3;
    public static readonly SHORT_TRACK_VOLUME: number = 0.3;

    public static readonly ARENA_MIN_LEVEL_TO_SHOW_BUTTON: number = 4;
    public static readonly ARENA_MIN_LEVEL: number = 8;

    public static readonly CLAN_MIN_LEVEL_TO_SHOW_BUTTON: number = 8;
    public static readonly CLAN_MIN_LEVEL: number = 15;

    public static readonly MAIN_CHAT_ROOM: string = 'MAIN';
    public static readonly CLAN_CHAT_ROOM: string = 'CLAN';
    public static readonly PRIVAT_CHAT_ROOM: string = 'PRIVAT';
    
    public static readonly FORWARDED_MESSAGE_PREFIX: string = "4682d7c4-f542-4385-9e0a-6edf74e8aa5b";
    public static readonly SMILEY_MESSAGE_PREFIX: string = "f5570448-1e96-4f5e-973d-e37dfbe2ceb4...";

    public static readonly MAX_MESSAGE_LENGTH: number = 1000;

    public static readonly MIN_ELIXIR_SETTINGS_STAIR = 25;
    public static readonly MIN_BACKPACK_STAIR = 1;

    public static readonly MIN_GIFTS_LEVEL = 15;

    public static readonly LEGEND_HERO_MIN_STAIR = 30;
    public static readonly MYTH_HERO_MIN_STAIR = 100;
    public static readonly TOP_HERO_MIN_STAIR = 500;

    public static readonly CLAN_THEME_FREE_CHANGES: number = 3;
    public static readonly CLAN_THEME_CHANGE_PRICE: number = 250;

    public static LANG_TYPE = Lang.RU;
    public static LANG: any = RU;

    public static MUSIC_LOADED: boolean = false;

    public static CURRENCY = 'RUB';

    public static LOGGED_LOAD_FIVE: boolean = false;
    public static LOGGED_LOAD_HALF: boolean = false;
    public static LOGGED_GAME_STARTED: boolean = false;

    public static KNIGHT_OFFSET_X: number = 0;

    public static LOCATION: LocationType = LocationType.RU;

    public static AVAILABLE_LANGUAGES: LangProp[] = [];

    public static setEn() {
        Settings.LANG = EN;
        Settings.LANG_TYPE = Lang.EN;
    }

    public static setRu() {
        Settings.LANG = RU;
        Settings.LANG_TYPE = Lang.RU;
    }

    public static setLangDefaults(): void {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let lang = urlParams.get('lang');

        // if (!lang && process.env.VUE_APP_IS_MOBILE_APP) {
        //     var locationService = new LocationService();
        //     locationService.getIp().then((ipDto: LocationIpDto) => {
        //             locationService.getLocationDataByIp(ipDto.ip).then((locationDataDto: LocationDataDto) => {
        //                 if (locationDataDto.country_code2 == "RU") {
        //                     Settings.LOCATION = LocationType.RU;
        //                 }
        //             })
        //         }
        //     );
        // }

        if (IntegrationUtils.isCrazyUser() || IntegrationUtils.isKongUser() || window.location.href.includes('mirland.mobi') || window.location.href.includes('en.lgtest.fun')) {
            Settings.setEn();
        } else if (IntegrationUtils.isYandexUser()) {
            if (window.location.href.includes('yandex.com') && !window.location.href.includes('ru') && lang !== 'ru') {
                Settings.setEn();
            } else if (lang === 'en') {
                Settings.setEn();
            } else {
                Settings.setRu();
            }
        } else if (IntegrationUtils.isI18NEnabled()) {
            if (((lang && lang.includes('en')) || queryString.includes('lglass.mobi')) ||
                queryString.includes('mirland.mobi') || window.location.hostname.includes('mirland.mobi')) {
                Settings.setEn();
            } else {
                console.log('ru = ' + queryString);
                Settings.setRu();
            }

            if (IntegrationUtils.isMystoreUser()) {
                const curr = urlParams.get('currency');
                if (curr) {
                    Settings.CURRENCY = curr;
                }
            }
        }

        Object.keys(LangDescription).forEach((key, index) => {
            let langProp = new LangProp();
            langProp.langId = key;
            langProp.langDescr = Object.values(LangDescription)[index];
            if (key == 'RU' || key == 'EN') {
                langProp.available = true;
            } else {
                langProp.available = false;
            }

            this.AVAILABLE_LANGUAGES.push(langProp);
        })

        PlayerStore.setRemindCampaignRules(true);
    }
}
