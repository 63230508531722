import {injectable} from "inversify"
import {AppMetrica} from "capacitor-appmetrica-plugin";
import { Tr } from "@/i18n/i18n";

@injectable()
export class AppmetricaService {

    public static readonly TRANSACTION_EVENT = "Transaction event";

    async activate() {
        console.log(Tr('appmetrica.active'))

        AppMetrica.activate({
            apiKey: process.env.VUE_APP_APPMETRICA_SDK_KEY,
            logs: true
        }).then(() => {
            console.log(Tr('appmetrica.success'))
        }).catch(() => {
            console.log(Tr('appmetrica.error'))
        });
    }

    async reportEvent(name: string, params: object) {

        AppMetrica.reportEvent(
            {
                name: name,
                params: params
            });
    }

    static async reportEventStatic(name: string, params: object) {
        //console.log("AppMetrica event: " + name + " params: " + JSON.stringify(params))

        AppMetrica.reportEvent(
             {
                 name: name,
                 params: params
             });
    }
}
