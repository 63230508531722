import Scene = Phaser.Scene;
import {Keys} from "@/constants/key";
import Sprite = Phaser.GameObjects.Sprite;
import SpriteUtils from "@/utils/sprite-utils";
import PlayerStore from "@/store/player";
import QualityUtils from "@/utils/quality-utils";

export default class CU {

    public static WINDOW_RESIZED: boolean = false;

    public static readonly ETALON_CANVAS_WIDTH: number = 600;

    public static readonly MIN_DESKTOP_RATIO: number = 1.4;

    public static CANVAS_WIDTH: number = CU.windowWidth();
    public static CANVAS_HEIGHT: number = CU.windowHeight();

    public static CSS_WIDTH: number = CU.cssWidth();
    public static CSS_HEIGHT: number = CU.cssHeight();

    public static S: number = CU.CANVAS_WIDTH / CU.ETALON_CANVAS_WIDTH;

    public static CSS_S: number = CU.CSS_WIDTH / CU.ETALON_CANVAS_WIDTH;

    private static wall: Sprite | undefined;

    public static scale(num: number) {
        return num * CU.S;
    }

    public static shiftLeft(x: number) {
        return x * CU.S;
    }

    public static shiftTop(x: number) {
        return x * CU.S;
    }

    public static shiftBottom(canvas: HTMLCanvasElement, y: number) {
        return canvas.height - y * CU.S;
    }

    public static shiftRight(x: number) {
        return CU.CANVAS_WIDTH - x * CU.S;
    }

    public static blockCanvas(scene: Scene) {
        CU.wall = SpriteUtils.addSprite(scene!, 0, 0, Keys.ATLAS_UI, Keys.UI_BLACK).setOrigin(0, 0)
            .setDepth(1000000).setAlpha(1).setInteractive();
        SpriteUtils.setDispSize(CU.wall, CU.CANVAS_WIDTH, 2000);
    }

    public static isCanvasBlocked(): boolean {
        return !!CU.wall;
    }

    public static unblockCanvas() {
        if (CU.wall) {
            CU.wall.destroy();
            CU.wall = undefined;
        }
    }

    public static setCanvasBlockerCustomDepth(depth: number) {
        if (CU.wall) {
            CU.wall.setDepth(depth);
        }
    }

    /**
     * Returns true if canvas RESIZED and scene needs to be reloaded
     */
    public static checkCanvasSize(scene: Scene, reloadScene: () => unknown): boolean {
        if (CU.WINDOW_RESIZED) {
            CU.WINDOW_RESIZED = false;
            CU.resizeCanvas('game-container');
            scene.game.scale.resize(CU.CANVAS_WIDTH, CU.CANVAS_HEIGHT);
            reloadScene();
            return true;
        }
        return false;
    }

    public static forceResizeCanvas() {
        CU.resizeCanvas('game-container');
    }

    public static resizeCanvas(containerId: string) {
        CU.CANVAS_WIDTH = CU.windowWidth();
        CU.CANVAS_HEIGHT = CU.windowHeight();

        CU.CSS_WIDTH = CU.cssWidth();
        CU.CSS_HEIGHT = CU.cssHeight();

        //PlayerStore.logClientError('CANV.W = ' + CU.CANVAS_WIDTH + '; CANV.H = ' + CU.CANVAS_HEIGHT + '; CSS.W = ' + CU.CSS_WIDTH + '; CSS.H = ' + CU.CSS_HEIGHT);

        CU.S = CU.CANVAS_WIDTH / CU.ETALON_CANVAS_WIDTH;

        const body = document.body;
        const canvasParrent = <HTMLElement> document.getElementById(containerId);

        body.style.width = String(CU.CSS_WIDTH) + 'px';
        body.style.height = String(CU.CSS_HEIGHT) + 'px';

        canvasParrent.style.width = String(CU.CSS_WIDTH) + 'px';
        canvasParrent.style.height = String(CU.CSS_HEIGHT) + 'px';

        let col: HTMLCollectionOf<HTMLCanvasElement> = document.getElementsByTagName('canvas');
        if (col && col.length > 0) {
            const canvas = col.item(0);
            if (canvas) {
                canvas.style.width = String(CU.CSS_WIDTH) + 'px';
                canvas.style.height = String(CU.CSS_HEIGHT) + 'px';
            }
        }
    }

    public static windowWidth() {
        if (CU.isDesktop()) { // DESKTOP:

            return Math.min(window.visualViewport.width, this.getMaxWidthDesktop());

        } else { // MOBILE:

            return QualityUtils.getReccommendedWidth();
        }
    }

    public static windowHeight() {
        if (CU.isDesktop()) { // DESKTOP:

            return window.visualViewport.height;

        } else { // MOBILE:

            let width = CU.windowWidth();
            return Math.floor(width / window.visualViewport.width * window.visualViewport.height);
        }
    }

    public static cssWidth() {
        if (CU.isDesktop()) { // DESKTOP:

            return Math.min(window.visualViewport.width, this.getMaxWidthDesktop());

        } else { // MOBILE:

            return window.visualViewport.width;
        }
    }

    public static cssHeight() {
        return Math.floor(window.visualViewport.height);
    }

    public static virtualWindowHeight() {
        return Math.ceil(CU.windowHeight() / CU.S);
    }

    public static isDesktop() {
        return window.visualViewport.height < window.visualViewport.width * 1.4;
    }

    private static getMaxWidthDesktop() {
        return Math.min(600, Math.floor(window.visualViewport.height / CU.MIN_DESKTOP_RATIO));
    }
}
