import {ActionTree, Module, GetterTree, MutationTree} from "vuex";
import orderBy from 'lodash/orderBy';
import http from "@/axios";
import {RootState} from "@/store/types";
import {MessageMail, MailRoom, MailState} from "./types";
import {MESSAGES_PER_FETCH} from './constants';

const namespaced: boolean = false;

const state: MailState = {
  mailRooms: [],
  mailPartnerId: null,
  mailMessages: [],
  support: false
};

const getters: GetterTree<MailState, any> = {
  mailMessages: ({ mailMessages }) => mailMessages,
  mailRooms: ({ mailRooms }) => mailRooms,
  mailPartner: ({ mailPartnerId }) => mailPartnerId,
  support: ({ support }) => support,
  mailMessageIds: ({ mailMessages }) => mailMessages.map((m: MessageMail) => m.id),
  mailLatestMessage: ({ mailMessages }) => mailMessages.length && mailMessages[0],
  mailEarliestMessage: ({ mailMessages }) => mailMessages.length && mailMessages[mailMessages.length -1],
};

const mutations: MutationTree<MailState> = {
  SET_MAIL_ROOMS(st, payload: {data: MailRoom[]}) {
    st.mailRooms = payload.data;
  },
  SET_MAIL_PARTNER(st, payload: {id: number | null, support: boolean}) {
    st.mailPartnerId = payload.id;
    st.support = payload.support;
    st.mailMessages.length = 0;
  },
  SET_MAIL_RESPONSE_DATA(st: MailState, payload: { data: MessageMail[] }) {
    const { data } = payload;
    const mailMessages = [ ...st.mailMessages, ...data ];
    st.mailMessages = orderBy(mailMessages, ['time'], ['desc']);
  },
  SET_MAIL_LATEST_MESSAGES(st: MailState, payload: { data: MessageMail[] }) {
    st.mailMessages = [...payload.data, ...st.mailMessages];
  },
  CLEAR_MAIL_MESSAGES(st: MailState) {
    st.mailMessages = [];
  },
};

const actions: ActionTree<MailState, any> = {
  fetchMailRooms({ commit }) {
    const endpoint = '/api/mail/list';
    return http.get(endpoint)
      .then(r => r.data)
      .then((data: MessageMail[]) => {
        commit('SET_MAIL_ROOMS', { data });
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },

  setMailPartner({ commit, getters }, payload: {id: number | null, support: boolean}) {
    const { mailPartner } = getters;
    if ( mailPartner === payload.id ) return;
    commit('SET_MAIL_PARTNER', payload);
  },

  fetchMailList({ commit, getters }) {
    const { mailMessages, mailMessageIds, mailPartner } = getters;
    if (!mailPartner) return;
    const endpoint =`api/mail/dialog/${mailPartner}/list`;
    const params = { from: 0, to: MESSAGES_PER_FETCH };
    if (mailMessages.length) {
      params.from = mailMessages.length;
      params.to = params.from + MESSAGES_PER_FETCH;
    }
    return http.get(endpoint, { params })
      .then(r => r.data)
      .then((data: MessageMail[]) => {
        const filteredMessages = data.filter((m: MessageMail) => {
          return !mailMessageIds.includes(m.id);
        });
        commit('SET_MAIL_RESPONSE_DATA', { data: filteredMessages });
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },

  fetchLatestMailMessages({commit, getters, dispatch}) {
    const { mailLatestMessage, mailPartner } = getters;
    if (!mailPartner) return;
    if (!mailLatestMessage) return dispatch('fetchMailList');
    const afterId = mailLatestMessage.id;
    const endpoint = `api/mail/dialog/${mailPartner}/list/latest`;
    const params = { afterId };
    return http.get(endpoint, { params })
      .then(r => r.data)
      .then((data: MessageMail[]) => {
        commit('SET_MAIL_LATEST_MESSAGES', { data });
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },

  postMailMessage({ getters }, payload: { text: string }) {
    const { mailPartner, support } = getters;
    if (!mailPartner) return;
    let sup = !!support;
    const { text } = payload;
    const endpoint = `api/mail/dialog/${mailPartner}/send?support=${sup}`;
    return http.post(endpoint, text);
  },

  clearMailMessages({ commit }) {
    commit('CLEAR_MAIL_MESSAGES');
  },
}

export const mailModule: Module<MailState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
